import cx from 'classnames';
import React from 'react';
import styles from './QuoteModule.module.scss';
import IconQuote from 'ui/icons/icon_quote.svg';
import { Quote } from 'ui/components/1-atoms/Quote';

export type QuoteModuleTheme = 'big' | 'small';

export interface QuoteModuleProps {
	className?: string;
	quoteModuleText?: string;
	quoteModuleName?: string;
	quoteModuleTitle?: string;
	quoteModuleTheme: QuoteModuleTheme;
}

export const QuoteModule: React.FC<QuoteModuleProps> = ({
	className,
	quoteModuleText,
	quoteModuleName,
	quoteModuleTitle,
	quoteModuleTheme = 'big',
}) => {
	return (
		<div className={cx(styles.QuoteModule, styles[`QuoteModule___${quoteModuleTheme}`], className)}>
			{quoteModuleTheme === 'big' && <IconQuote className={styles.QuoteModule_quoteIcon} />}
			<div className={styles.QuoteModule_border}>
				<Quote style={'primary'} className={styles.QuoteModule_heading}>
					{quoteModuleTheme === 'small' ? `“${quoteModuleText}”` : quoteModuleText}
				</Quote>
				<p className={styles.QuoteModule_text}>{quoteModuleName}</p>
				<p className={styles.QuoteModule_text}>{quoteModuleTitle}</p>
			</div>
		</div>
	);
};
