import cx from 'classnames';
import styles from './FormfieldTextarea.module.scss';
import { ErrorMessage } from 'ui/components/1-atoms/Form/ErrorMessage';
import { Label } from 'ui/components/1-atoms/Form/Label';
import { ariaError } from 'helpers/form/ariaError/ariaError';
import { ariaLabel } from 'helpers/form/ariaLabel/ariaLabel';

export interface FormfieldTextareaProps extends UI.FormfieldBaseProps {
	defaultValue?: string;
	placeholder?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register?: any;
	rows?: number;
}

export const FormfieldTextarea: React.FC<FormfieldTextareaProps> = ({
	className,
	state,
	id,
	label,
	placeholder,
	register,
	errorMessage,
	defaultValue,
	rows,
	name,
}) => {
	const { required, disabled, hasError, isHidden } = state ?? {};

	return (
		<div
			className={cx(
				styles.FormfieldTextarea,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
			)}
		>
			{!isHidden && (
				<Label id={id} className={styles.FormfieldTextarea_label} state={state}>
					{label}
				</Label>
			)}
			<textarea
				className={cx(styles.FormfieldTextarea_input, { [styles.hasError]: hasError })}
				name={name}
				id={id}
				aria-label={ariaLabel(state, label)}
				aria-describedby={ariaError(state, name, id)}
				disabled={disabled}
				placeholder={placeholder}
				defaultValue={defaultValue}
				rows={rows || 5}
				{...(register && { ...register(name, { required }) })}
			/>
			{hasError && <ErrorMessage id={name + id}>{errorMessage}</ErrorMessage>}
		</div>
	);
};
