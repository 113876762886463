import cx from 'classnames';
import React from 'react';
import styles from './Lead.module.scss';

export interface LeadProps extends UI.Text {
	size?: 'sm' | 'lg';
}

export const Lead: React.FC<LeadProps> = ({ children, className, id, size = 'sm' }) => (
	<p id={id} className={cx(styles.Lead, className, styles[`Lead___${size}`])}>
		{children}
	</p>
);
