import cx from 'classnames';
import styles from './Container.module.scss';

export type ContainerWidth = 'std' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'fluid';
export type BottomSpacing = 'none' | 'sm' | 'md' | 'lg';

export interface ContainerProps {
	className?: string;
	id?: string;
	width?: ContainerWidth;
	spacingBottom?: UI.ModuleSpacing;
	noPadding?: boolean;
	children: React.ReactNode;
	section?: boolean;
}

export const Container: React.FC<ContainerProps> = ({
	className,
	id,
	children,
	section,
	width = 'std',
	spacingBottom = 'none',
	noPadding,
}) => {
	const Wrapper = section ? 'section' : 'div';

	return (
		<Wrapper
			className={cx(
				styles.Container,
				styles[`Container___width_${width}`],
				{ [styles[`Container___spacingBottom_${spacingBottom}`]]: spacingBottom !== 'none' },
				{ [styles.Container___noPadding]: noPadding },
				className,
			)}
			id={id}
		>
			{children}
		</Wrapper>
	);
};
