import cx from 'classnames';
import styles from './HeroFocus.module.scss';
import { ArrowRightLink } from 'ui/components/1-atoms/Action/Link/ArrowRightLink';
import { Container } from 'ui/components/4-habitats/Container';
import { Lead } from 'ui/components/1-atoms/Lead';
import { Picture } from 'ui/components/1-atoms/Media/Picture';
import { Paragraph } from 'ui/components/1-atoms/Paragraph';

export type HeroFocusTheme = 'light' | 'dark';

export interface HeroFocusProps {
	className?: string;
	kicker?: string;
	description: string;
	image: UI.Image;
	link: UI.LinkSimple;
	theme: HeroFocusTheme;
}

export const HeroFocus: React.FC<HeroFocusProps> = ({
	kicker,
	description,
	className,
	image,
	link,
	theme = 'light',
}) => (
	<>
		<Container section width={'fluid'} className={cx(styles.HeroFocus, className, styles[`HeroFocus___${theme}`])}>
			{image && <Picture {...image} altText={undefined} className={styles.HeroFocus_picture} />}
			<div className={styles.HeroFocus_textbox}>
				{kicker && (
					<Lead className={styles.HeroFocus_kicker} size={'sm'}>
						{kicker}
					</Lead>
				)}

				{description && (
					<Paragraph className={styles.HeroFocus_description} strong={true}>
						{description}
					</Paragraph>
				)}
				{link && (
					<ArrowRightLink {...link} className={styles.HeroFocus_link}>
						{link.name}
					</ArrowRightLink>
				)}
			</div>
		</Container>
	</>
);
