import { ArticlePicture } from 'ui/components/2-molecules/Article/ArticlePicture';
import { Container } from 'ui/components/4-habitats/Container';

export const ArticlePictureFeature: React.FC<Content.ArticlePicture> = ({ content }) => (
	<Container section width="sm" spacingBottom="sm">
		<ArticlePicture
			picture={{
				image: {
					altText: content?.properties?.image?.alt,
					url: content?.properties?.image?.url,
				},
				caption: content?.properties?.caption ?? '',
			}}
		/>
	</Container>
);
