import cx from 'classnames';
import styles from './ArticleAuthor.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading';
import { Link } from 'ui/components/1-atoms/Action/Link';
import { ArticleBylineProps } from '../ArticleByline/ArticleByline';

export interface ArticleAuthorProps extends ArticleBylineProps {
	bio: string;
	email: string;
}

export const ArticleAuthor: React.FC<ArticleAuthorProps> = ({ className, fullName, workTitle, email, bio }) => (
	<footer className={cx(styles.ArticleAuthor, className)}>
		<div className={styles.ArticleAuthor_name}>
			<Heading headingLevel="h4" style="md">
				{fullName}
			</Heading>
		</div>
		<div className={styles.ArticleAuthor_title}>{workTitle}</div>
		<div className={styles.ArticleAuthor_email}>
			<Link className={styles.ArticleAuthor_link} url={email}>
				{email}
			</Link>
		</div>
		<div className={styles.ArticleAuthor_bio}>{bio}</div>
	</footer>
);
