import cx from 'classnames';
import { ArrowRightLink } from 'ui/components/1-atoms/Action/Link/ArrowRightLink';
import { Heading } from 'ui/components/1-atoms/Heading';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';
import { LinkList } from 'ui/components/2-molecules/LinkList';
import { Paragraph } from 'ui/components/1-atoms/Paragraph';
import { Picture } from 'ui/components/1-atoms/Media/Picture';
import styles from './NavigationCard.module.scss';

export type NavigationCardsLinkType = 'card' | 'normal'; // card = link on whole card, standard = only the link is clickable

export interface NavigationCardProps {
	className?: string;
	heading?: string;
	headingSize?: 'xs' | '2xs';
	text?: string;
	image?: UI.Image;
	icon?: {
		url: string;
		altText: string;
		size?: 'small' | 'large';
	};
	link?: UI.LinkBaseProps;
	linkType?: NavigationCardsLinkType;
	arrowLink?: boolean;
	linkList?: UI.LinkBaseProps[];
	centered?: boolean;
	backgroundColor?: 'white' | 'light';
}

export const NavigationCard: React.FC<NavigationCardProps> = ({
	className,
	heading,
	headingSize = 'xs',
	text,
	image,
	icon,
	link,
	linkType = 'card',
	arrowLink,
	linkList,
	centered,
	backgroundColor = 'white',
}) => {
	const NavigationCardContainer = link?.url && !linkList && linkType === 'card' ? Link : 'div';
	const LinkContainer = arrowLink ? ArrowRightLink : Link;

	return (
		<NavigationCardContainer
			className={cx(
				styles.NavigationCard,
				className,
				styles[`NavigationCard___bg-${backgroundColor}`],
				styles[`NavigationCard___linkType-${linkList ? 'standard' : linkType}`],
				{
					[styles.NavigationCard___centered]: centered,
				},
				{
					[styles.NavigationCard___icon]: icon?.url,
				},
				{
					[styles[`NavigationCard___icon-${icon?.size || 'small'}`]]: icon?.url,
				},
				{
					[styles.NavigationCard___image]: image?.url,
				},
			)}
			{...link}
		>
			{image?.url && (
				<div className={styles.NavigationCard_image}>
					<Picture aspectRatio={1} isCover url={image.url} altText={heading} sizes="100vw" />
				</div>
			)}
			{icon?.url && (
				<div
					className={cx(
						styles.NavigationCard_iconContainer,
						styles[`NavigationCard_iconContainer___${icon.size}`],
					)}
				>
					<Picture
						className={styles.NavigationCard_icon}
						isCover
						url={icon.url}
						altText={heading}
						sizes="100vw"
					/>
				</div>
			)}
			{heading && (
				<Heading className={styles.NavigationCard_heading} headingLevel="h2" style={headingSize}>
					{heading}
				</Heading>
			)}
			{text && <Paragraph className={styles.NavigationCard_text}>{text}</Paragraph>}
			{link && !linkList && (
				<LinkContainer
					className={cx(styles.NavigationCard_link, { [styles.NavigationCard_arrowLink]: arrowLink })}
				>
					{link.name}
				</LinkContainer>
			)}
			{linkList && <LinkList className={styles.NavigationCard_linkList} links={linkList} textDecoration />}
		</NavigationCardContainer>
	);
};
