import { ContainerWidth } from 'ui/components/4-habitats/Container';

export const widthMapper = (width?: Content.ModuleWidth): ContainerWidth => {
	switch (width) {
		case 'Small':
			return 'sm';
		case 'Medium':
			return 'md';
		default:
			return 'std';
	}
};
