import cx from 'classnames';
import { generateId } from 'helpers/id';
import { useEffect, useRef, useState } from 'react';
import styles from './CludoSearchFilterSelect.module.scss';
import { useEscapeKey } from 'ui/hooks/useEscapeKey';
import { useOutsideClick } from 'ui/hooks/useOutsideClick';
import FocusTrap from 'focus-trap-react';
import { cludoRequiredIdsAndClasses } from 'helpers/cludo';

export interface CludoSearchFilterSelectProps {
	className?: string;
	id: string;
	name: string;
	style?: UI.FormfieldStyle;
	toggleButtonText: string;
	dropdownAriaLabel: string;
	queryId: string;
}

export const CludoSearchFilterSelect: React.FC<CludoSearchFilterSelectProps> = ({
	className,
	id,
	style = 'bordered',
	toggleButtonText,
	queryId,
	dropdownAriaLabel,
}) => {
	const { searchFiltersClass } = cludoRequiredIdsAndClasses;
	const [dropdownId] = useState(`formgroup-${id}-${generateId()}`);
	const [toggleId] = useState(`toggle-${id}-${generateId()}`);
	const [filterOpen, setFilterOpen] = useState(false);
	const wrapperRef = useRef<HTMLDivElement>(null);

	const toggleDropdown = (e?: React.MouseEvent | React.KeyboardEvent) => {
		e?.preventDefault();
		setFilterOpen(!filterOpen);
	};

	const closeFilter = (): void => {
		setFilterOpen(false);
	};

	useEscapeKey(closeFilter);
	useOutsideClick(closeFilter, wrapperRef);
	useEffect(closeFilter, [queryId]); // Close filter when search query changes

	return (
		<FocusTrap active={filterOpen}>
			<div className={cx(styles.CludoSearchFilterSelect, className)} ref={wrapperRef}>
				<button
					id={toggleId}
					className={cx(styles.CludoSearchFilterSelect_toggle, [
						styles[`CludoSearchFilterSelect_toggle___${style}`],
					])}
					onClick={toggleDropdown}
					onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && toggleDropdown(e)}
					aria-expanded={filterOpen}
					aria-haspopup="listbox"
					aria-labelledby="filter-dropdown filter-button"
					tabIndex={0}
				>
					<span>{toggleButtonText}</span>
				</button>
				<div
					id={dropdownId}
					className={cx(searchFiltersClass, styles.CludoSearchFilterSelect_dropdown, {
						[styles.CludoSearchFilterSelect_dropdown___closed]: !filterOpen,
					})}
					aria-expanded={filterOpen}
					role="listbox"
					aria-label={dropdownAriaLabel}
					aria-labelledby="filter-dropdown"
					tabIndex={-1}
					onKeyDown={(e) => e.key === 'Escape' && closeFilter()}
				/>
			</div>
		</FocusTrap>
	);
};
