import cx from 'classnames';
import styles from './FormfieldGroup.module.scss';
import { ErrorMessage } from 'ui/components/1-atoms/Form/ErrorMessage';
import { ariaError } from 'helpers/form/ariaError/ariaError';

export interface FormfieldGroupProps {
	className?: string;
	id: string;
	name: string;
	label: UI.Label;
	children: React.ReactNode;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register?: any;
	errorMessage?: string;
	state?: UI.FormfieldStateBase;
}

export const FormfieldGroup: React.FC<FormfieldGroupProps> = ({
	className,
	id,
	name,
	label,
	register,
	errorMessage,
	state,
	children,
}) => {
	const { required, disabled, hasError } = state ?? {};

	return (
		<fieldset
			name={name}
			className={cx(
				styles.FormfieldGroup,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
			)}
			{...(register && { ...register(name, { required }) })}
			aria-describedby={ariaError(state, name, id)}
		>
			{label.text && (
				<legend
					id={id}
					className={cx(styles.FormLabel, styles.FormfieldGroup_label, {
						[styles.FormfieldGroup_label___hidden]: label.visuallyHidden,
					})}
				>
					{label.text}
					{required && (
						<span className={styles.FormLabel___required} aria-hidden="true">
							*
						</span>
					)}
				</legend>
			)}
			{children}
			{hasError && <ErrorMessage id={name + id}>{errorMessage}</ErrorMessage>}
		</fieldset>
	);
};
