import cx from 'classnames';
import { ariaError } from 'helpers/form/ariaError/ariaError';
import { ariaLabel } from 'helpers/form/ariaLabel/ariaLabel';
import { ErrorMessage } from 'ui/components/1-atoms/Form/ErrorMessage';
import { Label } from 'ui/components/1-atoms/Form/Label';
import { useFormContext } from 'ui/contexts/FormContext';
import styles from './FormfieldString.module.scss';
import { emailRegex } from 'helpers/emailRegex';

export interface FormfieldStringProps extends UI.FormfieldBaseProps {
	defaultValue?: string;
	placeholder?: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register?: any;
	type: 'text' | 'email' | 'search' | 'number' | 'password' | 'tel' | 'url' | 'date';
	style?: UI.FormfieldStyle;
	min?: number;
	max?: number;
	pattern?: string;
	rules?: Record<string, unknown>;
	handleChange?: (e: string) => void;
	forwardedRef?: React.RefObject<HTMLInputElement>;
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const FormfieldString: React.FC<FormfieldStringProps> = ({
	className,
	state,
	id,
	label,
	type,
	style = 'bordered',
	register,
	placeholder,
	name,
	defaultValue,
	min,
	max,
	pattern,
	rules,
	errorMessage,
	handleChange,
	forwardedRef,
	onKeyDown,
}) => {
	const { required, disabled, hasError } = state ?? {};
	const dictionary = useFormContext();

	const emailRules =
		type === 'email'
			? {
					pattern: {
						value: emailRegex,
						message: dictionary?.emailErrorMessage ?? 'Incorrect email format',
					},
			  }
			: null;

	return (
		<div
			className={cx(
				styles.FormfieldString,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
			)}
		>
			<Label id={id} className={styles.FormfieldString_label} state={state}>
				{label}
			</Label>
			<input
				type={type}
				className={cx(
					styles.FormfieldString_input,
					styles.Input___string,
					{
						[styles.hasError]: hasError,
					},
					styles[`FormfieldString_input___${style}`],
				)}
				name={name}
				id={id}
				disabled={disabled}
				placeholder={placeholder}
				defaultValue={defaultValue}
				aria-label={ariaLabel(state, label)}
				aria-describedby={ariaError(state, type, id)}
				min={min}
				max={max}
				pattern={pattern}
				onChange={handleChange ? (e) => handleChange(e.target.value) : null}
				onKeyDown={onKeyDown}
				ref={forwardedRef}
				{...(register &&
					register(name, {
						required: {
							value: required,
							message: dictionary?.requiredErrorMessage ?? 'This field is required',
						},
						...emailRules,
						...rules,
					}))}
			/>
			{hasError && <ErrorMessage id={`${id}-error`}>{errorMessage}</ErrorMessage>}
		</div>
	);
};
