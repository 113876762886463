import cx from 'classnames';
import { useState, useEffect, useRef } from 'react';
import styles from './Picture.module.scss';
import { GetImageUrl, GetSrcSetString } from 'helpers/getImageUrl';
import config from 'helpers/imageSizes';
import { inView } from 'helpers/inView';

export interface PictureProps extends UI.Image {
	focalPoint?: { left: number; top: number };
	sizes?: string;
	lazyload?: boolean;
	objectFitFallback?: boolean;
	isCover?: boolean;
	aspectRatio?: number;
	className?: string;
}

export const Picture: React.FC<PictureProps> = ({
	isCover = true,
	sizes = '100vw',
	url,
	altText,
	lazyload = true,
	objectFitFallback = true,
	focalPoint,
	aspectRatio,
	className,
}) => {
	const { fallbackSize } = config.images;

	const [src, setSrc] = useState('');
	const [imgSrcSet, setImgSrcSet] = useState('');
	const [sourceSrcSet, setSourceSrcSet] = useState('');
	const [isFallback, setIsFallback] = useState(false);

	const pictureRef = useRef(null);
	const isSvg = url?.endsWith('.svg');

	const sourceType = isSvg ? 'image/svg+xml' : 'image/webp';

	useEffect(() => {
		if (objectFitFallback) objectFit();
		if (lazyload) inView({ elm: pictureRef.current, callback: reveal });
		if (!lazyload) reveal();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url]);

	const reveal = () => {
		const srcSetString = GetSrcSetString({ url, focalPoint, aspectRatio });
		const srcSetStringWebp = GetSrcSetString({ url, focalPoint, format: 'webp', aspectRatio });
		const srcString = GetImageUrl({ url, width: fallbackSize });

		setImgSrcSet(isSvg ? url : srcSetString);
		setSourceSrcSet(isSvg ? url : srcSetStringWebp);
		setSrc(isSvg ? url : srcString);
	};

	const objectFit = () => {
		// If the useragent doesn't support ObjectFit (IE), we need to
		// add fallback image to the picture element
		const supportObjectFit = 'objectFit' in document.documentElement.style;

		if (!supportObjectFit) setIsFallback(true);
	};
	return (
		<picture
			ref={pictureRef}
			className={cx(
				styles.Picture,
				{ [styles.Picture___cover]: isCover },
				{ [styles.isFallback]: isFallback },
				className,
			)}
			style={isFallback ? { backgroundImage: `url(${src})` } : {}}
		>
			{!isFallback && src && (
				<>
					<source sizes={sizes} type={sourceType} srcSet={sourceSrcSet} />
					<img
						src={src}
						alt={altText ?? ''}
						className={cx(styles.Picture_asset, className)}
						srcSet={imgSrcSet}
						sizes={sizes}
					/>
				</>
			)}
		</picture>
	);
};
