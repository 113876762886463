import { PageContextProps } from 'application/contexts/PageContext';
import { OpenGraphProps } from 'ui/components/2-molecules/OpenGraph';

const getOpenGraphTitle = (pageName: string, openGraphTitle?: string, title?: string) => {
	if (openGraphTitle && openGraphTitle?.length > 0) return openGraphTitle;
	if (title && title.length > 0) return title;
	return pageName ?? '';
};

const getLeadText = (pageContext: Partial<PageContextProps>): string => {
	const pageContent = pageContext.content;
	if (
		pageContext.documentType === 'contentPage' ||
		pageContext.documentType === 'segmentPage' ||
		pageContext.documentType === 'homePage'
	) {
		return pageContent?.pageHeader[0]?.content.properties?.lead;
	} else if (pageContext.documentType === 'articlePage') {
		return pageContent?.lead;
	}
	return null;
};

const getHeroImage = (pageContext: Partial<PageContextProps>): Content.Image | null => {
	const pageContent = pageContext.content;
	if (
		pageContext.documentType === 'contentPage' ||
		pageContext.documentType === 'segmentPage' ||
		pageContext.documentType === 'homePage'
	) {
		return pageContent.pageHeader[0]?.content.properties.image ?? null;
	} else if (pageContext.documentType === 'articlePage') {
		return pageContent?.image[0]?.content.properties?.image ?? null;
	}
	return null;
};

const getOpenGraphDescription = (description: string, openGraphDescription: string, leadTxt: string): string => {
	if (openGraphDescription && openGraphDescription.length > 0) return openGraphDescription;
	if (description && description.length > 0) return description;
	return leadTxt ?? '';
};

// NOTE: this method really should be unit-testet, but keep getting this error from Jest:
// "Jest failed to parse a file. This happens e.g. when your code or its dependencies use non-standard JavaScript syntax, or when Jest is not configured to support such syntax."
/**
 * We create the OpenGraphProps with following priority:
 * 	1) An OpenGraph object inserted in the settings of the page in Umbraco.
 * 	2) Take the Title and desription from the META fields on the page in Umbraco
 * 	3) We fall back to pageName, image and lead (manchet) text from a Hero module, if that is inserted on the page.
 */
export const getOpenGraphProps = (
	pageContext: Partial<PageContextProps>,
	openGraph: Content.OpenGraphProperties,
	siteName: string,
	fallbackImage: Content.Image,
	title?: string,
	description?: string,
): OpenGraphProps => {
	return {
		title: getOpenGraphTitle(pageContext?.name, openGraph?.title, title),
		description: getOpenGraphDescription(description, openGraph?.description, getLeadText(pageContext)),
		image: openGraph?.image ?? getHeroImage(pageContext) ?? fallbackImage,
		imageText: openGraph?.image ? openGraph?.imageText : undefined,
		pageUrl: pageContext.url,
		siteName: siteName,
	};
};
