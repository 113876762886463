import { functionNotNull } from 'helpers/functionNotNull/functionNotNull';
import { useCallback, useEffect } from 'react';

const KEY_NAME_ESC = 'Escape';
const KEY_EVENT_TYPE = 'keydown';

export const useEscapeKey = (onEscapeKeyDown: () => void): void => {
	const handleEscKey = useCallback(
		(event) => {
			if (event.key === KEY_NAME_ESC && functionNotNull(onEscapeKeyDown)) {
				onEscapeKeyDown();
			}
		},
		[onEscapeKeyDown],
	);

	useEffect(() => {
		document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false);

		return () => {
			document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false);
		};
	}, [handleEscKey]);
};
