import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { usePageContext } from 'application/contexts/PageContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { ContentPageOverviewParams } from 'application/repositories/contentPageOverviewRepository';
import { alphabeticallySortArray } from 'helpers/array/array';
import { convertTimestampToDate, getLatestTimestamp, getOldestTimestamp, listViewPrettyDate } from 'helpers/date/date';
import { generateMonthSelectboxes, generateYearSelectboxes } from 'helpers/form/selectbox/selectboxGenerators';
import { generateId } from 'helpers/id';
import logger from 'helpers/logger';
import { mapSubjects } from 'helpers/mappers/subjectMapper';
import { handleShyHeading, removeSpecialCharactersAndTrim, replacePartOfString } from 'helpers/string/string';
import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import { FormfieldCheckboxCallbackProps } from 'ui/components/2-molecules/Form/FormfieldCheckbox';
import { PaginationProps } from 'ui/components/2-molecules/Navigation/Pagination';
import { SearchResultItemProps } from 'ui/components/2-molecules/SearchResultItem';
import { SearchFilterProps } from 'ui/components/3-organisms/SearchFilter';
import SearchFilterCombobox from 'ui/components/3-organisms/SearchFilter/SearchFilter';
import { SearchResultView, SearchResultViewProps } from 'ui/components/3-organisms/SearchResultView';
import { getSortedContentPagesFromApi } from '../../../application/adapters/contentPageOverviewAdapter';
import { getFormattedDateRange } from 'helpers/date/date';
import { useRouter } from 'next/router';
import { pushSearchFormParamsToUrl } from 'helpers/pushSearchFormParamsToUrl';

export const ContentPageOverviewFeature: React.FC<Content.ContentPageOverview> = ({ content }) => {
	const PageContext = usePageContext();
	const SiteContext = useSiteContext();
	const router = useRouter();
	const { themeSettings } = PageContext;
	const {
		category: contentCategory,
		link,
		rootFolder,
		lead,
		allResultsLabel,
		resultsLabel,
		oldestYear,
		contentOverviewSubjects,
		showSubjectDropdown,
		fallbackText,
	} = content.properties ?? {};
	const { host, culture } = SiteContext;
	const filterBaseId = generateId();

	const mapSubjectDocType = (category: string): Content.ContentPageCategory => {
		const theCategory = category as Content.ContentPageCategory;
		if (
			theCategory === 'Annoncering' ||
			theCategory === 'Nyhed' ||
			theCategory === 'Publikation' ||
			theCategory === 'Udbud' ||
			theCategory === 'Event'
		) {
			return theCategory;
		} else {
			logger.warn(
				'ContentPageOverviewFeature mapAndSetSubjectDocType() - category was not recognized: ',
				category,
			);
			return null;
		}
	};

	const onYearFilterChanged = (id: string, boxes: FormfieldCheckboxCallbackProps[]) => {
		const filteredBoxes = boxes.filter((item) => item.checked);
		const value = filteredBoxes.length > 0 ? filteredBoxes[0].value : '';

		setSearchYear(value);
	};

	const onMonthfilterChanged = (id: string, boxes: FormfieldCheckboxCallbackProps[]) => {
		const filteredBoxes = boxes.filter((item) => item.checked);
		const value = filteredBoxes.length > 0 ? filteredBoxes[0].value : '';

		setSearchMonth(value);
	};

	const onSubjectFilterChanged = (id: string, boxes: FormfieldCheckboxCallbackProps[]) => {
		const filteredBoxes = boxes.filter((item) => item.checked);
		const value = filteredBoxes.map((item) => item.value);

		setSearchSubjects(value);
	};

	type GenerateSearchFieldComboboxesParams = {
		subjects: Models.Settings.SubjectSetting[];
		startYear: number;
		defaultYearValue?: number;
		defaultMonthValue?: number;
		defaultTopic?: number;
	};

	const generateSearchFieldComboboxes = ({
		subjects,
		startYear,
		defaultYearValue,
		defaultMonthValue,
		defaultTopic,
	}: GenerateSearchFieldComboboxesParams): SearchFilterCombobox[] => {
		const sortedSubjects = alphabeticallySortArray(subjects, 'subjectName');
		const toReturn: SearchFilterCombobox[] = [
			{
				id: filterBaseId + '-year',
				labelText: dictionary.getValue('Search.Filter.YearFilterToggle', null, 'Vælg årstal'),
				name: 'year',
				checkBoxes: generateYearSelectboxes(
					startYear,
					new Date().getFullYear(),
					filterBaseId + '-year',
					defaultYearValue,
				),
				toggleButtonText: dictionary.getValue('Search.Filter.YearFilterToggle', null, 'Vælg årstal'),
				onSelectedChanged: onYearFilterChanged,
				multiselect: false,
			},
			{
				id: filterBaseId + '-month',
				labelText: dictionary.getValue('Search.Filter.MonthFilterToggle', null, 'Vælg måned'),
				name: 'month',
				checkBoxes: generateMonthSelectboxes(filterBaseId + '-month', culture, defaultMonthValue),
				toggleButtonText: dictionary.getValue('Search.Filter.MonthFilterToggle', null, 'Vælg måned'),
				onSelectedChanged: onMonthfilterChanged,
				multiselect: false,
			},
		];

		if (sortedSubjects?.length > 0) {
			toReturn.push({
				id: filterBaseId + '-subject',
				labelText: dictionary.getValue('Search.Filter.SubjectToggle', null, 'Vælg emne'),
				name: 'subject',
				checkBoxes: sortedSubjects.map((item, index) => ({
					id: `${filterBaseId + '-subject'}-${index}`,
					labelText: item.subjectName,
					name: `checkbox-subject-${item.id}`,
					value: item.id.toString(),
					checked: defaultTopic ? defaultTopic === item.id : false,
				})),
				toggleButtonText: dictionary.getValue('Search.Filter.SubjectFilterToggle', null, 'Vælg emne'),
				onSelectedChanged: onSubjectFilterChanged,
				multiselect: false,
			});
		}

		return toReturn;
	};

	const subjects = contentOverviewSubjects && showSubjectDropdown ? mapSubjects(contentOverviewSubjects) : [];
	const dictionary = useDictionaryContext();
	const [results, setResults] = useState<SearchResultItemProps[]>([]);
	const [initialResults, setInitialResults] = useState<SearchResultItemProps[] | null>(null);
	const [paginationResponse, setPaginationResponse] = useState<Models.TeaserPagination>(null);
	/** Use this to increment when ever pagination is interacted with */
	const [nextPage, setNextPage] = useState(0);

	/* Determine the start year for the comboboxes */
	const oldestYearInt = convertTimestampToDate(oldestYear).getFullYear() ?? 0;
	// Events will be sorted by descending date, so we can use the first event of the initial results to determine the earliest event year
	const earliestEventYear =
		contentCategory === 'Event' && initialResults?.length > 0 ? new Date(results[0].dateTime).getFullYear() : 0;
	const comboBoxesStartYear =
		earliestEventYear >= oldestYearInt && earliestEventYear > 0
			? earliestEventYear
			: oldestYearInt > 0
			? oldestYearInt
			: new Date().getFullYear();
	const [comboBoxes, setComboBoxes] = useState<SearchFilterCombobox[]>(
		generateSearchFieldComboboxes({ subjects, startYear: comboBoxesStartYear }),
	);

	const [searchTerm, setSearchTerm] = useState('');
	const [searchIncrementer, setSearchIncrementer] = useState(0);
	const [searchYear, setSearchYear] = useState<string>('');
	const [searchMonth, setSearchMonth] = useState<string>('');
	const [searchSubjects, setSearchSubjects] = useState<string[]>([]);
	const [searchParams, setSearchParams] = useState<ContentPageOverviewParams>(null);
	/**toggleSearch is just a boolean to listen for in useEffect. When ever it changes will initiate a useEffect where
	 * searchParams are updated and there upon useSWR kicks in. */
	const [toggleSearch, setToggleSearch] = useState(false);
	const [inSearchMode, setInSearchMode] = useState(false);
	const formFieldRef = useRef<HTMLInputElement>(null);

	const overwriteSearchField = (newString: string) => {
		if (formFieldRef?.current) {
			formFieldRef.current.value = newString;
		}
	};

	const mapAndSetResults = (results: Models.TeaserArticle[]) => {
		const sortedResultsDesc = results.map<SearchResultItemProps>((item) => {
			let dateString: string;

			if (contentCategory === 'Event') {
				if (item.end) {
					dateString = getFormattedDateRange({
						startDate: new Date(item.date),
						endDate: new Date(item.end),
						culture,
					});
				} else {
					dateString = listViewPrettyDate(new Date(item.date), culture, true);
				}
			} else {
				dateString = listViewPrettyDate(new Date(item.date), culture);
			}

			return {
				dateString,
				link: { url: item.url, name: item.url },
				dateTime: item.date !== null ? new Date(Date.parse(item.date)) : new Date(),
				heading: handleShyHeading({ heading: item.header, shyHeading: item.shyHeader }),
				text: item.lead ?? '',
			};
		});

		setResults(sortedResultsDesc);
	};

	const basicClearBeforeSearch = () => {
		mapAndSetResults([]);
		setPaginationResponse(null);
		setNextPage(0);
	};

	const fullClear = () => {
		basicClearBeforeSearch();
		setSearchYear('');
		setSearchMonth('');
		setSearchSubjects([]);
		setComboBoxes(generateSearchFieldComboboxes({ subjects, startYear: comboBoxesStartYear }));
		setSearchTerm('');
		pushSearchFormParamsToUrl({
			url: window.location.href.split('?')[0],
			searchTerm: undefined,
			searchYearValue: undefined,
			searchMonthValue: undefined,
			topics: undefined,
			page: undefined,
			router,
		});

		// Overwrite current value of the searchField
		overwriteSearchField('');
	};

	/****** API fetches ******************/
	const { data, isLoading } = useSWR(searchParams, getSortedContentPagesFromApi, {
		revalidateOnFocus: false,
	});

	/***** useEffects ***********/
	useEffect(() => {
		if (!initialResults) {
			setInitialResults(results);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [results]);

	// On mount we look for URL search params and set the search state accordingly
	useEffect(() => {
		const initialSearchParams = new URLSearchParams(window.location.search);
		const searchParamsTerm = initialSearchParams.get('search');
		const searchYearValue = initialSearchParams.get('year');
		const searchMonthValue = initialSearchParams.get('month');
		const topics = initialSearchParams.get('topics')?.split(',');

		// If no relevant search params are present, do nothing
		if (!searchParamsTerm && !searchYearValue && !searchMonthValue && !topics) return;

		// Set form states based on URL search params
		handleSearchStringChanged(searchParamsTerm ?? '');
		setSearchYear(searchYearValue ?? '');
		setSearchMonth(searchMonthValue ?? '');
		setSearchSubjects(topics ?? []);

		// Set comboboxes based on URL search params
		if (searchYearValue || searchMonthValue || topics) {
			setComboBoxes(
				generateSearchFieldComboboxes({
					subjects,
					startYear: comboBoxesStartYear,
					defaultYearValue: searchYearValue ? Number(searchYearValue) : undefined,
					defaultMonthValue: searchMonthValue ? Number(searchMonthValue) : undefined,
					defaultTopic: topics ? Number(topics[0]) : undefined,
				}),
			);
		}

		// Trigger search
		setToggleSearch(!toggleSearch);
		setInSearchMode(true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const searchYearValue = searchYear;
		const searchMonthValue = searchMonth;
		let fromDate: string;
		const toDate = getLatestTimestamp(searchYearValue, searchMonthValue);

		if (contentCategory === 'Event' && !searchYearValue && !searchMonthValue) {
			// Limit to events that starts today or later if no year or month is selected
			fromDate = new Date().toISOString().split('T')[0] + 'T00:00:00.000Z';
		} else {
			fromDate = getOldestTimestamp(searchYearValue, searchMonthValue);
		}

		const theSearch: ContentPageOverviewParams = {
			rootKey: rootFolder?.key,
			categories: [{ name: mapSubjectDocType(contentCategory) }],
			subjects: subjects.filter((subject) => {
				return searchSubjects.find((searchSubject) => subject.id === Number(searchSubject));
			}),
			nextPage: nextPage,
			to: toDate,
			from: fromDate,
			direction: contentCategory === 'Event' ? 'ascending' : 'descending',
			searchTerm: searchTerm,
			incrementer: searchIncrementer,
			host: host,
		};
		setSearchIncrementer(searchIncrementer + 1);
		setSearchParams(theSearch);

		// Push URL search params to browser URL history to reflect the search
		pushSearchFormParamsToUrl({
			url: window.location.href,
			searchTerm,
			searchYearValue,
			searchMonthValue,
			page: nextPage > 0 ? (nextPage + 1).toString() : undefined, // Add 1 to page number to reflect the visible page number (1-indexed)
			topics: searchSubjects,
			router,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nextPage, toggleSearch]);

	useEffect(() => {
		if (data?.searchResults) {
			mapAndSetResults(data.searchResults);
			setPaginationResponse(data.pagination);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	/*****  Eventhandlers  *******/
	const handleSearchStringChanged = (value: string) => {
		const formattedSearchTerm = removeSpecialCharactersAndTrim(value);
		setSearchTerm(formattedSearchTerm);
	};

	const onPageChange = (selectedItem: { selected: number }) => {
		setNextPage(selectedItem.selected);
	};

	const onSubmitClicked = () => {
		basicClearBeforeSearch();

		// Since we remove specialCharacters everytime we update searchTerm with new value
		// We also update the searchField so user can see what term has actually been used on submit.
		overwriteSearchField(searchTerm);
		setToggleSearch(!toggleSearch);
		setInSearchMode(true);
	};

	const onClearClicked = () => {
		fullClear();
		setToggleSearch(!toggleSearch);
		setInSearchMode(false);
	};

	const filterProps: SearchFilterProps = {
		heading: dictionary.getValue('Search.Filter.Heading', null, 'Søgning og filtrering'),
		searchField: {
			id: filterBaseId + '-textInput',
			name: 'search-filter',
			label: dictionary.getValue('Search.Filter.SearchPlaceholder', null, 'Indtast søgeord'),
			defaultValue: searchTerm,
			placeholder: `${dictionary.getValue('Search.Filter.SearchPlaceholder', null, 'Indtast søgeord')}...`,
			handleSearchStringChanged,
		},
		submitBtn: {
			text: dictionary.getValue('Search.Filter.Submit', null, 'Søg'),
			onClick: onSubmitClicked,
		},
		formFieldComboboxes: comboBoxes,
		clearSearchBtn: {
			text: dictionary.getValue('Search.Filter.Clear', null, 'Nulstil søgning'),
			onClick: onClearClicked,
		},
		forwardedFormfieldStringRef: formFieldRef,
	};

	const getCorrectResultListHeading = () => {
		return inSearchMode
			? resultsLabel && resultsLabel?.length > 0
				? resultsLabel
				: dictionary.getValue(
						'Search.ResultList.Heading.Result',
						null,
						'Vi fandt ${num} resultater, der matcher din søgning',
				  )
			: allResultsLabel && allResultsLabel?.length > 0
			? allResultsLabel
			: dictionary.getValue('Search.ResultList.Heading.All', null, 'Alle resultater');
	};

	const resultListProps = (): SearchResultViewProps['resultListProps'] => {
		return {
			heading: replacePartOfString(
				getCorrectResultListHeading(),
				'${num}',
				paginationResponse?.totalResults.toString() ?? '0',
			),
			link: link,
			results: results,
			isLoading: isLoading,
		};
	};

	const pagination: PaginationProps = {
		pageCount: paginationResponse?.totalPages ?? 0,
		initialPageIndex: 0,
		pageText: dictionary.getValue('Search.ResultList.PaginationAriaLabel', null, 'Side'),
		onPageChange: onPageChange,
		forcePageIndex: paginationResponse?.totalPages > 0 ? nextPage : -1,
	};

	return (
		<SearchResultView
			hero={{
				heading: handleShyHeading(content?.properties),
				lead: lead,
				theme: themeSettings?.heroAndBreadcrumbColorTheme,
			}}
			filterProps={filterProps}
			resultListProps={resultListProps()}
			pagination={pagination}
			fallbackText={isLoading ? null : fallbackText}
		/>
	);
};
