import cx from 'classnames';
import styles from './HeroContentPage.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading';
import { Lead } from 'ui/components/1-atoms/Lead';
import { ArticlePicture } from 'ui/components/2-molecules/Article';
import { Container } from 'ui/components/4-habitats/Container';

export interface HeroContentPageProps extends UI.HeroBase {
	image?: UI.ArticlePicture;
}

export const HeroContentPage: React.FC<HeroContentPageProps> = ({
	heading,
	lead,
	className,
	image,
	theme = 'dark',
}) => (
	<header
		className={cx(styles.HeroContentPage, className, styles[`HeroContentPage___theme-${theme}`], {
			[styles.HeroContentPage___picture]: image,
		})}
	>
		<Container
			width={'fluid'}
			className={cx(styles.HeroContentPage_top, {
				[styles.HeroContentPage_top___picture]: image,
			})}
		>
			<Container width={'std'}>
				<div className={styles.HeroContentPage_textbox}>
					<Heading headingLevel="h1" style="xl">
						{heading}
					</Heading>
					{lead && (
						<Lead className={styles.HeroContentPage_lead} size={'sm'}>
							{lead}
						</Lead>
					)}
				</div>
			</Container>
		</Container>
		{image && (
			<Container width={'fluid'} className={styles.HeroContentPage_gradient}>
				<Container>
					<ArticlePicture picture={image} pictureClassName={styles.HeroContentPage_picture} />
				</Container>
			</Container>
		)}
	</header>
);
