import cx from 'classnames';
import { generateId } from 'helpers/id';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useFormContext } from 'ui/contexts/FormContext';
import styles from './NewsSubscriptionForm.module.scss';
import { Button } from 'ui/components/1-atoms/Action';
import { ErrorMessage } from 'ui/components/1-atoms/Form';
import { Spinner } from 'ui/components/1-atoms/Media';
import {
	FormfieldSelectOption,
	FormfieldCheckboxProps,
	FormfieldString,
	FormfieldSelect,
	FormfieldCombobox,
	FormfieldCheckbox,
} from 'ui/components/2-molecules/Form';
import { Form } from '../Form';

interface FormFieldBase {
	label: string;
	placeholder: string;
}
export interface NewsSubscriptionFormData {
	email: string;
	name?: string;
	occupation?: string;
	topics: string[];
}

export interface NewsSubscriptionFormProps {
	className?: string;
	formfields: {
		email?: FormFieldBase;
		/** Name is optional in first version */
		name?: FormFieldBase;
		/** Occupation is optional in first version */
		occupation?: FormFieldBase & {
			options: FormfieldSelectOption[];
		};
		topics?: FormFieldBase & {
			options: FormfieldCheckboxProps[];
			isLoadingOptions: boolean;
		};
	};
	submitHandler: (data: NewsSubscriptionFormData) => void;
	submitButtonText: string;
	submitError?: string;
	isSubmitting?: boolean;
	cancelHandler: () => void;
	cancelButtonText: string;
	defaultInputErrorMessage: string;
	errorSummaryMessage: string;
	firstOptionSelectsAll?: boolean;
}

export const NewsSubscriptionForm: React.FC<NewsSubscriptionFormProps> = ({
	className,
	formfields,
	submitHandler,
	submitButtonText,
	submitError,
	isSubmitting,
	cancelHandler,
	cancelButtonText,
	defaultInputErrorMessage,
	errorSummaryMessage,
	firstOptionSelectsAll,
}) => {
	const dictionary = useFormContext();
	const topicsNamespace = 'topics';
	const { name, email, occupation, topics } = formfields ?? {};
	const [selectAll, setSelectAll] = useState(false);
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<NewsSubscriptionFormData>({
		mode: 'onBlur',
		defaultValues: {
			topics: [],
		},
	});

	const topicsOptions = firstOptionSelectsAll ? topics?.options?.slice(1) : topics?.options;

	return (
		<Form
			className={cx(styles.NewsSubscriptionForm, className)}
			onSubmit={handleSubmit(submitHandler)}
			errorSummary={Object.keys(errors).length}
			getErrorTxtFromSummary={(errorSummary) => errorSummaryMessage.replace('${num}', errorSummary.toString())}
		>
			<div className={styles.NewsSubscriptionForm_fields}>
				{name && (
					<FormfieldString
						id={`name-${generateId()}`}
						name="name"
						className={styles.NewsSubscriptionForm_field}
						type="text"
						label={name.label}
						placeholder={name.placeholder}
						state={
							errors.name
								? {
										hasError: true,
										required: true,
								  }
								: { required: true }
						}
						errorMessage={errors.name?.message || defaultInputErrorMessage}
						register={register}
					/>
				)}

				{email && (
					<FormfieldString
						id="email"
						name="email"
						className={styles.NewsSubscriptionForm_field}
						type="email"
						label={email.label}
						placeholder={email.placeholder}
						state={
							errors.email
								? {
										hasError: true,
										required: true,
								  }
								: { required: true }
						}
						errorMessage={errors.email?.message || defaultInputErrorMessage}
						register={register}
					/>
				)}

				{occupation?.options?.length > 0 && (
					<FormfieldSelect
						id={`occupation-${generateId()}`}
						name="occupation"
						className={styles.NewsSubscriptionForm_field}
						label={occupation.label}
						options={[{ text: occupation.placeholder, value: '', disabled: true }, ...occupation.options]}
						defaultValue=""
						state={
							errors.occupation
								? {
										hasError: true,
										required: true,
								  }
								: { required: true }
						}
						errorMessage={errors.occupation?.message || defaultInputErrorMessage}
						register={register}
					/>
				)}

				{(topics?.options?.length > 0 || topics?.isLoadingOptions) && (
					<Controller
						control={control}
						name={topicsNamespace}
						rules={{
							required: {
								value: true,
								message: dictionary?.multiSelectErrorMessage ?? 'Please select at least one option',
							},
						}}
						shouldUnregister={true}
						render={({ field: { onChange, value } }) => {
							return (
								<FormfieldCombobox
									id={`${topicsNamespace}-${generateId()}`}
									name={topicsNamespace}
									className={styles.NewsSubscriptionForm_field}
									label={{
										text: topics.label,
										visuallyHidden: false,
									}}
									checkBoxes={[]}
									toggleButtonText={
										value?.length > 0
											? topicsOptions
													.filter((option) => value.includes(option.value?.toString()))
													.map((option) => option.labelText)
													.join('; ')
											: topics.placeholder
									}
									state={
										errors.topics
											? {
													hasError: true,
													required: true,
											  }
											: { required: true }
									}
									errorMessage={errors.topics?.message || defaultInputErrorMessage}
									multiselect
								>
									{firstOptionSelectsAll && topicsOptions.length > 1 && (
										<FormfieldCheckbox
											id={`${topicsNamespace}-all`}
											name={`${topicsNamespace}-all`}
											checked={selectAll}
											onChange={(e) => {
												if (e.target.checked) {
													onChange(topicsOptions?.map((option) => option.value));
													setSelectAll(true);
												} else {
													onChange([]);
													setSelectAll(false);
												}
											}}
											value={topics.options?.[0]?.value}
											labelText={topics.options?.[0]?.labelText}
										/>
									)}
									{topicsOptions?.map((option, index) => (
										<FormfieldCheckbox
											key={option.value}
											id={`${topicsNamespace}-${index}`}
											name={`${topicsNamespace}.${index}`}
											checked={value?.includes(option.value)}
											onChange={(e) => {
												if (e.target.checked) {
													onChange([...value, option.value]);
												} else {
													onChange(value?.filter((val) => val !== option.value));
												}
											}}
											value={option.value}
											labelText={option.labelText}
										/>
									))}
									{topics?.isLoadingOptions &&
										(dictionary?.loadingOptionsMessage ?? 'Loading options...')}
								</FormfieldCombobox>
							);
						}}
					/>
				)}
			</div>
			{submitError && (
				<ErrorMessage className={styles.NewsSubscriptionForm_submitError}>{submitError}</ErrorMessage>
			)}
			<div className={styles.NewsSubscriptionForm_action}>
				<Button
					className={styles.NewsSubscriptionForm_submit}
					type="submit"
					style="primary"
					disabled={isSubmitting}
				>
					{submitButtonText}
					{isSubmitting && <Spinner size="small" color="light" />}
				</Button>
				<Button
					className={styles.NewsSubscriptionForm_cancel}
					style="link"
					onClick={(e) => {
						e.preventDefault();
						cancelHandler && cancelHandler();
					}}
				>
					{cancelButtonText}
				</Button>
			</div>
		</Form>
	);
};
