import { subscribeToNewsletterLists } from 'application/adapters/newsletterAdapter';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { usePageContext } from 'application/contexts/PageContext';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { FormfieldCheckboxProps } from 'ui/components/2-molecules/Form/FormfieldCheckbox';
import { Modal } from 'ui/components/3-organisms/Modal';
import { NewsSubscriptionForm, NewsSubscriptionFormData } from 'ui/components/3-organisms/NewsSubscriptionForm';
import { NewsSubscriptionReceipt } from 'ui/components/3-organisms/NewsSubscriptionReceipt';

export const NewsSubscriptionFormFeature: React.FC<Content.NewsSubscriptionForm> = ({ content }) => {
	const router = useRouter();
	const pageContext = usePageContext();
	const dictionary = useDictionaryContext();
	const { title, text, receiptTitle, receiptText, anchorName } = content?.properties || {};
	const [isOpen, setIsOpen] = useState(false);
	const [submittedData, setSubmittedData] = useState(null);
	const [submitStatus, setSubmitStatus] = useState<'notSubmitted' | 'submitting' | 'submitted'>('notSubmitted');
	const [submitError, setSubmitError] = useState('');

	const mappedTopics =
		content?.subjects
			?.filter((topic: Models.Settings.SubjectSetting) => topic.ubivoxListId !== null)
			?.map(
				(topic: Models.Settings.SubjectSetting) =>
					({
						id: topic.id.toString(),
						labelText: topic.subjectName,
						value: topic.ubivoxListId?.toString(),
						checked: false,
						name: 'topics',
					} as FormfieldCheckboxProps),
			) ?? [];

	const formfields = {
		email: {
			label: dictionary.getValue('Form.Email', null, 'Email'),
			placeholder: dictionary.getValue('Form.EmailPlaceholder', null, 'Enter your email'),
		},
		topics:
			// We don't show dropdown with topics if only one is available any way.
			mappedTopics.length > 1
				? {
						label: dictionary.getValue('Form.Topics', null, 'Topics'),
						placeholder: dictionary.getValue('Form.Topics.Placeholder', null, 'Select topics'),
						options: [
							{
								id: 'all',
								labelText: dictionary.getValue('Form.Topics.SelectAll', null, 'Select all topics'),
								value: 'All',
								checked: false,
								name: 'topics',
							} as FormfieldCheckboxProps,
							...mappedTopics,
						],
						isLoadingOptions: false,
				  }
				: undefined,
	};

	const getSubmitErrorText = (error: Models.SubscribeToNewsletterListsResponseError) => {
		switch (error) {
			case 'invalidEmailAddress':
				return dictionary.getValue(
					'Form.Newsletter.InvalidEmailAddress',
					null,
					'The email address uses an invalid format or a domain that is not allowed. Please check the email address and try again.',
				);
			case 'invalidMailingList':
				return dictionary.getValue(
					'Form.Newsletter.InvalidMailingList',
					null,
					'One or more of the selected topics are invalid or unavailable. Please try again later or select another topic.',
				);
			case 'alreadySubscribed':
				return dictionary.getValue(
					'Form.Newsletter.AlreadySubscribed',
					null,
					'The email address is already subscribed to the selected topics.',
				);
			default:
				return dictionary.getValue(
					'Form.Newsletter.Unsuccessful',
					null,
					'The sign-up could not be completed. Please try again later.',
				);
		}
	};

	const getSubmittedTopicsNames = (
		topics: Models.Settings.SubjectSetting[],
		submittedTopics: NewsSubscriptionFormData['topics'],
	) => {
		const topicNames = topics
			?.filter((topic) => submittedTopics?.includes(topic.ubivoxListId?.toString()))
			?.map((topic) => ({
				name: topic.subjectName,
			}));

		return topicNames;
	};

	const handleSubmit = async (formData: NewsSubscriptionFormData) => {
		const finalFormData = { ...formData };
		// In case there is only 1 topic available we don't show the dropdown to the end user. We pick the topic here behind the scenes.
		if (formData.topics.length === 0 && content?.subjects.length === 1) {
			finalFormData.topics = [content.subjects[0].ubivoxListId.toString()];
		}
		setSubmittedData(finalFormData);
		setSubmitStatus('submitting');
		setSubmitError('');

		subscribeToNewsletterLists({
			contentId: pageContext.id,
			...finalFormData,
			categoryIds: finalFormData?.topics?.map((topic) => Number(topic)) ?? [],
		})
			.then((res) => {
				if (res === 'success') {
					setSubmitStatus('submitted');
				} else {
					setSubmitStatus('notSubmitted');
					setSubmitError(getSubmitErrorText(res));
				}
			})
			.catch(() => {
				setSubmitStatus('notSubmitted');
				setSubmitError(getSubmitErrorText('unsuccessful'));
			});
	};

	const handleClose = () => {
		setIsOpen(false);
		setSubmitStatus('notSubmitted');
		setSubmitError('');
		router.push(location.href.replace(location.hash, ''), null, { shallow: true, scroll: false });
	};

	// Trigger modal on hashchange to valid hashname
	const toggleModalOnHashChange = (hash: string) => setIsOpen(hash === anchorName);

	const onRouteHashChange = (url: string) => {
		const hash = url?.split('#')[1];
		toggleModalOnHashChange(hash);
	};

	const onNativeHashChange = (event: HashChangeEvent) => {
		const hash = event.newURL ? event.newURL.split('#')[1] : location.hash.replace('#', '');
		toggleModalOnHashChange(hash);
	};

	useEffect(() => {
		onRouteHashChange(router.asPath); // Trigger modal if hash is present on initial load
		router.events.on('hashChangeComplete', onRouteHashChange);

		// To make sure that native links that do not use Next routing (e.g. links from Rich Text editors)
		// can trigger the modal, we also listen for the native JavaScript "hashchange" event.
		// Note: This can potentially be removed if we find a way to trigger a Next route change on
		// native links .
		window.addEventListener('hashchange', onNativeHashChange);

		return () => {
			// Remove events if component is unmounted
			router.events.off('hashChangeComplete', onRouteHashChange);
			window.removeEventListener('hashchange', onNativeHashChange);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [anchorName]);

	return (
		<Modal
			isOpen={isOpen}
			title={submitStatus === 'submitted' ? receiptTitle : title}
			text={submitStatus === 'submitted' ? receiptText : text}
			handleClose={handleClose}
			ariaClose={dictionary.getValue('Modal.Close', null, 'Close window')}
			buttonText={submitStatus === 'submitted' ? dictionary.getValue('Modal.Close', null, 'Close window') : null}
			buttonHandler={handleClose}
		>
			{submitStatus === 'submitted' ? (
				<NewsSubscriptionReceipt
					recipient={{
						email: submittedData?.email,
					}}
					recipientEmailLabel={formfields?.email?.label}
					topicGroups={
						// If there was only 1 option from beginning, the dropdown has not been shown to end user.
						// Therefore no need to inform about submitted topic
						content?.subjects?.length > 1
							? [
									// Note: The ability to have more than one topic group was postponed to a later
									// iteration, so for now we just supply one topic group with a generic topic group name.
									{
										name: dictionary.getValue('Form.Topics', null, 'Topics'),
										topics: getSubmittedTopicsNames(content?.subjects, submittedData?.topics),
									},
							  ]
							: []
					}
				/>
			) : (
				<NewsSubscriptionForm
					{...content.properties}
					formfields={formfields}
					submitHandler={handleSubmit}
					cancelHandler={handleClose}
					defaultInputErrorMessage={dictionary.getValue(
						'Form.DefaultInputErrorMessage',
						null,
						'Please fill in this field correctly',
					)}
					errorSummaryMessage={dictionary.getValue(
						'Form.ErrorSummaryMessage',
						null,
						'There are ${num} errors in the form',
					)}
					submitError={submitError}
					isSubmitting={submitStatus === 'submitting'}
					firstOptionSelectsAll
				/>
			)}
		</Modal>
	);
};
