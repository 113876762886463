import cx from 'classnames';
import React from 'react';
import styles from './LinkBars.module.scss';
import { generateId } from 'helpers/id';
import { LinkButton, LinkBar } from 'ui/components/1-atoms/Action';
import { Heading } from 'ui/components/1-atoms/Heading';

export interface LinkBarsProps {
	className?: string;
	heading?: string;
	button?: UI.LinkBaseProps;
	links: UI.LinkBaseProps[];
}

export const LinkBars: React.FC<LinkBarsProps> = ({ className, heading, links, button }) => {
	const linkButton = (buttonClassName: string) => {
		if (!button) return null;

		return (
			<LinkButton className={cx(styles.LinkBar_button, buttonClassName)} style="secondary" {...button}>
				{button.name}
			</LinkButton>
		);
	};

	const headingId = generateId();

	return (
		<nav className={cx(styles.LinkBars, className)} aria-labelledby={headingId}>
			{(heading || button) && (
				<div className={styles.LinkBars_header}>
					{heading && (
						<Heading id={headingId} headingLevel="h3" style="lg" className={styles.LinkBars_heading}>
							{heading}
						</Heading>
					)}
					{linkButton(styles.LinkBars_button___desktop)}
				</div>
			)}
			<ul className={styles.LinkBars_links}>
				{links.map((link, index) => (
					<li className={styles.LinkBars_link} key={index}>
						<LinkBar ariaLabel={link.name} {...link}>
							{link.name}
						</LinkBar>
					</li>
				))}
			</ul>
			{linkButton(styles.LinkBars_button___mobile)}
		</nav>
	);
};
