import React from 'react';
import cx from 'classnames';
import styles from './Paragraph.module.scss';

export interface ParagraphProps extends UI.Text {
	size?: 'md' | 'lg';
	strong?: boolean;
}

export const Paragraph: React.FC<ParagraphProps> = ({ children, className, size, strong }) => (
	<p
		className={cx(styles.Paragraph, className, styles[`Paragraph___${size}`], {
			[styles.Paragraph___strong]: strong,
		})}
	>
		{children}
	</p>
);
