import cx from 'classnames';
import styles from './SkipToMain.module.scss';
import { Link } from 'ui/components/1-atoms/Action/Link';

export interface SkipToMainProps {
	children: React.ReactNode;
	link?: string;
	title?: string;
}

export const SkipToMain: React.FC<SkipToMainProps> = ({ children, title, link }) => (
	<Link url={link} title={title} className={cx(styles.SkipToMain)}>
		{children}
	</Link>
);
