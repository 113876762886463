import classNames from 'classnames';
import { Heading } from 'ui/components/1-atoms/Heading';
import styles from './Linkbox.module.scss';
import { Link } from 'ui/components/1-atoms/Action/Link';
import { Icon } from 'ui/components/1-atoms/Media';
import { Container } from 'ui/components/4-habitats/Container';

export interface LinkboxProps {
	className?: string;
	heading?: string;
	linkboxItems?: UI.LinkSimple[];
	backgroundColor?: string;
	linkIcon?: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const Linkbox: React.FC<LinkboxProps> = ({
	className,
	heading,
	linkboxItems,
	backgroundColor = '#FFFFFF',
	linkIcon,
}) => {
	const LinkIcon = linkIcon;
	return (
		<Container width="fluid" className={styles.Linkbox_container}>
			<div className={classNames(styles.Linkbox, className)} style={{ backgroundColor: backgroundColor }}>
				{heading && (
					<Heading headingLevel="h3" style="xs" className={classNames(styles.Linkbox_heading)}>
						{heading}
					</Heading>
				)}
				{linkboxItems && (
					<ul className={classNames(styles.Linkbox_linksContainer)}>
						{linkboxItems.map((linkboxItem, index) => (
							<li key={index} className={classNames(styles.Linkbox_links)}>
								<Link
									url={linkboxItem.url}
									aria-label={linkboxItem.name}
									className={classNames(styles.Linkbox_link)}
									target={linkboxItem.target || '_blank'}
								>
									<Icon title="download" size="md" className={styles.Linkbox_icon}>
										{linkIcon ? <LinkIcon /> : <></>}
									</Icon>
									<span>{linkboxItem.name}</span>
								</Link>
							</li>
						))}
					</ul>
				)}
			</div>
		</Container>
	);
};
