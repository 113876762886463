import {
	ContentPageOverviewParams,
	getContentPagesFromApi,
} from 'application/repositories/contentPageOverviewRepository';

export const getSortedContentPagesFromApi = async (
	params: ContentPageOverviewParams,
): Promise<Content.ContentPageOverviewResponse> => {
	return await getContentPagesFromApi(params);
};
