import { Container } from 'ui/components/4-habitats/Container';
import { QuoteModule, QuoteModuleTheme } from 'ui/components/3-organisms/QuoteModule';

export const QuoteModuleFeature: React.FC<Content.QuoteModule> = ({ content }) => {
	const { quoteModuleTheme } = content?.properties ?? {};
	const quoteModuleThemeLowerCase = quoteModuleTheme?.toLowerCase() ?? 'light';

	return (
		<Container width="md" section>
			<QuoteModule {...content?.properties} quoteModuleTheme={quoteModuleThemeLowerCase as QuoteModuleTheme} />
		</Container>
	);
};
