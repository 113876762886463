import React from 'react';
import cx from 'classnames';
import styles from './Heading.module.scss';

export interface HeadingProps extends UI.Text {
	headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	style?: 'default' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
	id?: string;
}

export const Heading: React.FC<HeadingProps> = ({ children, className, headingLevel, id, style = 'default' }) =>
	React.createElement(
		headingLevel,
		{ id, className: cx(styles.Heading, styles[`Heading___${style}`], className) },
		children,
	);
