import { Container } from 'ui/components/4-habitats/Container';
import styles from './TeaserSlider.module.scss';
import cx from 'classnames';
import { SliderProps, Slider } from '../Slider';

export interface TeaserSliderProps extends SliderProps {
	backgroundColor?: 'light' | 'dark' | undefined;
	spacing?: UI.ModuleSpacing;
}

export const TeaserSlider: React.FC<TeaserSliderProps> = ({
	heading,
	slides,
	sliderOptions,
	buttons,
	isLoading,
	backgroundColor,
	spacing = 'md',
	fallbackText,
}) => {
	return (
		<Container
			section
			width="fluid"
			spacingBottom={spacing}
			className={cx(
				styles.TeaserSlider,
				backgroundColor && styles.TeaserSlider___bg,
				backgroundColor && styles[`TeaserSlider___bg-${backgroundColor}`],
			)}
		>
			<Container width="std">
				<Slider
					heading={heading}
					slides={slides}
					sliderOptions={sliderOptions}
					buttons={buttons}
					isLoading={isLoading}
					fallbackText={fallbackText}
				/>
			</Container>
		</Container>
	);
};
