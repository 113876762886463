import { useSiteContext } from 'application/contexts/SiteContext';
import Script from 'next/script';

export const CookieConsent: React.FC = () => {
	const siteContext = useSiteContext();

	if (!siteContext?.cookiebotId) return null;

	return (
		<Script
			id="Cookiebot"
			src="https://consent.cookiebot.com/uc.js"
			data-cbid={siteContext.cookiebotId}
			data-blockingmode="auto"
			type="text/javascript"
			strategy="afterInteractive"
			data-culture={siteContext.culture}
			async
		/>
	);
};
