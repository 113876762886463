import { useRouter } from 'next/router';
import { useEffect } from 'react';

export interface HtmlBlockProps {
	headHtml: string;
	bodyHtml: string;
}

export const HtmlBlock: React.FC<HtmlBlockProps> = ({ headHtml, bodyHtml }) => {
	const router = useRouter();

	const addHtmlBlock = (htmlBlock: string, appendToNode: HTMLElement) => {
		const fragment = document.createRange().createContextualFragment(htmlBlock);

		if (fragment.children.length === 0) return;

		const fragmentChildren = [...fragment.children];

		fragmentChildren.forEach((child) => appendToNode.appendChild(child));

		return {
			remove: () => {
				fragmentChildren.forEach((child) => appendToNode.removeChild(child));
			},
		};
	};

	useEffect(() => {
		if (!headHtml) return;

		const htmlBlock = addHtmlBlock(headHtml, document.head);

		return () => {
			htmlBlock.remove();
		};
	}, [headHtml, router.asPath]);

	useEffect(() => {
		if (!bodyHtml) return;
		const htmlBlock = addHtmlBlock(bodyHtml, document.body);

		return () => {
			htmlBlock.remove();
		};
	}, [bodyHtml, router.asPath]);

	return null;
};
