import cx from 'classnames';
import styles from './Label.module.scss';

export interface LabelProps {
	className?: string;
	id: string;
	state?: UI.FormfieldState;
	/** If true the label will be rendered as a P-tag and with aria-hidden="true" */
	asParagraph?: boolean;
	children: React.ReactNode;
}

export const Label: React.FC<LabelProps> = ({ className, id, children, state, asParagraph = false }) => {
	const LabelWrapper = asParagraph ? 'p' : 'label';
	return (
		<LabelWrapper
			className={cx(
				styles.FormLabel,
				{ [styles.hasError]: state?.hasError },
				{ [styles.isDisabled]: state?.disabled },
				{ [styles.isHidden]: state?.isHidden },
				className,
			)}
			htmlFor={!asParagraph ? id : null}
			aria-hidden={asParagraph}
		>
			<span>{children}</span>
			{state?.required && <span aria-hidden="true">*</span>}
		</LabelWrapper>
	);
};
