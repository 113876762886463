import cx from 'classnames';
import styles from './SearchFilter.module.scss';
import SearchIcon from 'ui/icons/icon-search.svg';
import { useEffect, useState } from 'react';
import { generateId } from 'helpers/id';
import { FormfieldCombobox, FormfieldComboboxBaseProps } from 'ui/components/2-molecules/Form/FormfieldCombobox';
import { functionNotNull } from 'helpers/functionNotNull/functionNotNull';
import { useBreakpoint } from 'ui/hooks/useBreakpoint';
import { Button } from 'ui/components/1-atoms/Action';
import { Heading } from 'ui/components/1-atoms/Heading';
import { Icon } from 'ui/components/1-atoms/Media';
import { FormfieldString } from 'ui/components/2-molecules/Form';
import { Form } from '../Form';

export default interface SearchFilterCombobox extends FormfieldComboboxBaseProps {
	labelText: string;
}
export interface SearchFilterBtn {
	text: string;
	onClick?: () => void;
}
export interface SearchFilterProps {
	id?: string;
	className?: string;
	heading: string;
	searchField: {
		id: string;
		name: string;
		label?: string;
		placeholder?: string;
		defaultValue?: string;
		handleSearchStringChanged: (value: string) => void;
	};
	formFieldComboboxes?: SearchFilterCombobox[];
	clearSearchBtn: SearchFilterBtn;
	submitBtn: SearchFilterBtn;
	forwardedFormfieldStringRef: React.RefObject<HTMLInputElement>;
	onGetErrortext?: (summary: number) => string;
	onSubmit?: (e: React.FormEvent) => void;
	cludoSearch?: UI.CludoSearchForm;
}

export const SearchFilter: React.FC<SearchFilterProps> = ({
	id,
	className,
	heading,
	searchField,
	formFieldComboboxes = [],
	clearSearchBtn,
	submitBtn,
	onGetErrortext,
	forwardedFormfieldStringRef,
	onSubmit,
	cludoSearch,
}) => {
	const isMobile = useBreakpoint('md', 'max');
	const [mobileOpen, setMobileOpen] = useState(false);
	const [formId] = useState(generateId(8));
	const [headingId] = useState(generateId(8));

	useEffect(() => {
		if (isMobile) setMobileOpen(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobile]);

	const toggleOnClick = (): void => {
		setMobileOpen(!mobileOpen);
	};

	const headingTxt = () => {
		if (isMobile) {
			return (
				<Button
					ariaExpanded={mobileOpen}
					ariaControls={formId}
					onClick={toggleOnClick}
					className={styles.SearchFilter_toggle}
				>
					{heading}
					<div className={styles.SearchFilter_toggleIcon}>
						{!mobileOpen && <div className={styles.SearchFilter_toggleIcon___vertical}></div>}
						<div className={styles.SearchFilter_toggleIcon___horizontal}></div>
					</div>
				</Button>
			);
		} else {
			return <>{heading}</>;
		}
	};

	const clearSearch = () => {
		if (functionNotNull(clearSearchBtn?.onClick)) clearSearchBtn?.onClick();
	};

	const handleClearSearch = (e?: React.MouseEvent): void => {
		e?.preventDefault();
		clearSearch();
	};

	const handleSubmit = (e?: React.MouseEvent): void => {
		e?.preventDefault();
		if (functionNotNull(submitBtn.onClick)) submitBtn?.onClick();
		onSubmit && onSubmit(e);
	};

	return (
		<div
			id={id}
			className={cx(styles.SearchFilter, className, {
				[styles.SearchFilter___open]: mobileOpen && isMobile,
			})}
		>
			{heading && (
				<Heading id={headingId} headingLevel={'h2'} style={'2xs'} className={styles.SearchFilter_heading}>
					{headingTxt()}
				</Heading>
			)}
			<Form
				id={cludoSearch?.formId ?? formId}
				ariaLabelledby={headingId}
				className={cx(styles.SearchFilter_form, {
					[styles.SearchFilter_form___open]: mobileOpen,
				})}
				getErrorTxtFromSummary={onGetErrortext}
			>
				<FormfieldString
					type="search"
					id={searchField.id}
					name={searchField.name}
					label={searchField.label}
					state={{ isHidden: true }}
					placeholder={searchField.placeholder}
					defaultValue={searchField.defaultValue}
					handleChange={searchField.handleSearchStringChanged}
					style={'borderlessWhite'}
					forwardedRef={forwardedFormfieldStringRef}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							e.preventDefault();
							handleSubmit();
						}
					}}
				/>
				<div className={styles.SearchFilter_filters}>
					{formFieldComboboxes &&
						formFieldComboboxes.map((item, key) => {
							return (
								<div className={styles.SearchFilter_comboboxContainer} key={key}>
									<FormfieldCombobox
										label={{ text: item.labelText, visuallyHidden: true }}
										style={'borderlessWhite'}
										{...item}
									/>
								</div>
							);
						})}
					<div className={styles.SearchFilter_btnWrapper}>
						{submitBtn && (
							<Button
								id={cludoSearch?.searchButtonId ?? null}
								type="submit"
								style="primary"
								className={styles.SearchFilter_submitBtn}
								onClick={handleSubmit}
							>
								{submitBtn.text}
								<Icon className={styles.SearchFilter_searchIcon}>
									<SearchIcon />
								</Icon>
							</Button>
						)}
						{clearSearchBtn && (
							<Button
								className={styles.SearchFilter_clearBtn}
								size={'sm'}
								onClick={handleClearSearch}
								style={'link'}
							>
								{clearSearchBtn.text}
							</Button>
						)}
					</div>
				</div>
			</Form>
		</div>
	);
};
