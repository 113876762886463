import cx from 'classnames';
import React from 'react';
import styles from './ArticleDate.module.scss';
import { Time } from '../../../1-atoms/Time';
import { listViewPrettyDate } from 'helpers/date/date';

export interface ArticleDate {
	className?: string;
	date: Date;
	locale: Content.Culture;
}

export const ArticleDate: React.FC<ArticleDate> = ({ className, date, locale }) => (
	<Time date={date}>
		<p className={cx(styles.ArticleDate, className)}>{listViewPrettyDate(date, locale)}</p>
	</Time>
);
