import { functionNotNull } from 'helpers/functionNotNull/functionNotNull';
import { useCallback, useEffect } from 'react';

export type mouseClickType = 'mouseup' | 'mousedown';

export const useOutsideClick = (
	onOutsideClick: () => void,
	ref: React.RefObject<HTMLElement>,
	clickType: mouseClickType = 'mouseup',
): void => {
	const handleClick = useCallback(
		(event) => {
			if (ref?.current?.contains && !ref.current.contains(event.target) && functionNotNull(onOutsideClick)) {
				onOutsideClick();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onOutsideClick],
	);

	useEffect(() => {
		document.addEventListener(clickType, handleClick);

		return () => {
			document.removeEventListener(clickType, handleClick);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleClick]);
};
