import cx from 'classnames';
import React from 'react';
import styles from './Subheading.module.scss';

export interface SubheadingProps extends UI.Text {
	style?: 'primary' | 'secondary';
}

export const Subheading: React.FC<SubheadingProps> = ({ children, className, style = 'primary' }) => (
	<p className={cx(styles.Subheading, styles[`Subheading___${style}`], className)}>{children}</p>
);
