import cx from 'classnames';
import React from 'react';

import styles from './Grid.module.scss';

export interface GridProps {
	className?: string;
	wrap?: boolean;
	container?: boolean;
	nogutter?: boolean;
	center?: boolean;
	full?: boolean;
	asList?: boolean;
	children?: React.ReactNode;
}

export const Grid: React.FC<GridProps> = ({ className, wrap, container, nogutter, center, full, asList, children }) => {
	const Wrapper = asList ? 'ul' : 'div';
	return (
		<Wrapper
			className={cx(
				styles.Grid,
				{ [styles.Grid___wrap]: wrap },
				{ [styles.Grid___container]: container },
				{ [styles.Grid___noGutter]: nogutter },
				{ [styles.Grid___center]: center },
				{ [styles.Grid___full]: full },
				className,
			)}
		>
			{children}
		</Wrapper>
	);
};
