export const spacingMapper = (space: Content.ModuleSpacing): UI.ModuleSpacing => {
	switch (space) {
		case 'Small':
			return 'sm';
		case 'Medium':
			return 'md';
		case 'Large':
			return 'lg';
		default:
			return 'none';
	}
};
