import { Link, LinkProps } from '../Link';
import ArrowRight from 'ui/icons/mst_arrow-right.svg';
import { Icon } from 'ui/components/1-atoms/Media';
import styles from './ArrowRightLink.module.scss';
import cx from 'classnames';

export const ArrowRightLink: React.FC<LinkProps> = ({ children, className, url, target, ...props }) => {
	const ArrowRightLinkContainer = url ? Link : 'div';

	return (
		<ArrowRightLinkContainer className={cx(styles.ArrowRightLink, className)} url={url} target={target} {...props}>
			{children}
			<Icon className={styles.ArrowRightLink_icon}>
				<ArrowRight />
			</Icon>
		</ArrowRightLinkContainer>
	);
};
