import Head from 'next/head';

export interface OpenGraphProps {
	title: string;
	description: string;
	image: Content.Image;
	imageText: string;
	pageUrl: string;
	siteName: string;
}

export const OpenGraph: React.FC<OpenGraphProps> = (props) => {
	const { title, siteName, imageText, image, description, pageUrl } = props ?? {};
	return (
		<Head>
			{pageUrl && <meta property="og:url" content={pageUrl} />}
			<meta property="og:type" content="website" />
			{title && <meta property="og:title" content={title} />}
			{description && <meta property="og:description" content={description} />}
			{image?.url && <meta property="og:image" content={`${image.url}?format=webp&width=600`} />}
			{imageText && <meta name="og:image:alt" content={imageText} />}
			{siteName && <meta property="og:site_name" content={siteName} />}
			<meta name="twitter:card" content="summary_large_image" />
		</Head>
	);
};
