import cx from 'classnames';
import styles from './HeroCanvasPage.module.scss';
import { Container } from 'ui/components/4-habitats/Container';
import { Heading } from 'ui/components/1-atoms/Heading';
import { Lead } from 'ui/components/1-atoms/Lead';
import { Picture } from 'ui/components/1-atoms/Media/Picture';

export interface HeroCanvasPageProps extends UI.HeroBase {
	image?: UI.Image;
	imageDisableSpacingBottom?: boolean;
	/**If searchFilter is present the image will be ignored */
	searchFilter?: React.ReactNode;
}

export const HeroCanvasPage: React.FC<HeroCanvasPageProps> = ({
	heading,
	lead,
	className,
	image,
	imageDisableSpacingBottom,
	searchFilter,
	theme = 'dark',
}) => {
	const showImage = image && !searchFilter;

	return (
		<div className={styles.HeroCanvasPage_wrapper}>
			<header
				className={cx(styles.HeroCanvasPage, className, styles[`HeroCanvasPage___theme-${theme}`], {
					[styles.HeroCanvasPage___picture]: showImage,
					[styles.HeroCanvasPage___picture___disableSpacingBottom]: showImage && imageDisableSpacingBottom,
				})}
			>
				<Container width={'std'} className={styles.HeroCanvasPage_content_wrapper}>
					<div
						className={cx(
							styles.HeroCanvasPage_textbox,
							{
								[styles.HeroCanvasPage_textbox___picture]: showImage,
							},
							{
								[styles.HeroCanvasPage_textbox___filter]: searchFilter,
							},
						)}
					>
						<Heading headingLevel="h1" style="lg">
							{heading}
						</Heading>
						{lead && (
							<Lead className={styles.HeroCanvasPage_lead} size={'sm'}>
								{lead}
							</Lead>
						)}
					</div>
					{showImage && <Picture className={styles.HeroCanvasPage_picture} {...image} />}
				</Container>
			</header>
			{searchFilter && (
				<Container className={styles.HeroCanvasPage_filterContainer} section>
					{searchFilter}
				</Container>
			)}
		</div>
	);
};
