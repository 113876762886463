import { getTeasersSorted } from 'application/adapters/teaserAdapter';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { NewsTeaserParams } from 'application/repositories/teaserRepository';
import { handleShyHeading } from 'helpers/string/string';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { SliderNewsItem } from 'ui/components/2-molecules/SliderNewsItem';
import { TeaserSlider } from 'ui/components/3-organisms/TeaserSlider';
import { getFormattedDateRange } from 'helpers/date/date';

export const TeaserFeature: React.FC<Content.Teaser> = ({ content }) => {
	const { link, rootFolder, category, teaserTags, newsItemsAmount, backgroundColor, spacing, fallbackText } =
		content?.properties ?? {};
	const dictionary = useDictionaryContext();
	const SiteContext = useSiteContext();
	const { host, culture } = SiteContext;
	const [news, setNews] = useState<JSX.Element[]>([]);
	const [params, setParams] = useState<NewsTeaserParams>(null);
	const mappedSpacing = spacing === 'none' ? spacing : 'md';
	const formattedBackgroundColor = (backgroundColor: Content.TeaserBackgroundColor) => {
		switch (backgroundColor) {
			case 'Light':
				return 'light';
			case 'Dark':
				return 'dark';
			default:
				return undefined;
		}
	};

	const sortAndSetNews = (data: Models.TeaserArticle[]) => {
		const dateFormattingOptions: Intl.DateTimeFormatOptions = {
			day: 'numeric',
			month: 'short',
			year: 'numeric',
		};

		const mappedSlides = data.map((slide, index) => {
			let dateString: string;

			if (category === 'Event') {
				const eventDateFormattingOptions: Intl.DateTimeFormatOptions = {
					...dateFormattingOptions,
					hour: 'numeric',
					minute: 'numeric',
				};

				if (slide.end) {
					dateString = getFormattedDateRange({
						startDate: new Date(slide.date),
						endDate: new Date(slide.end),
						culture,
						formattingOptions: eventDateFormattingOptions,
					});
				} else {
					dateString = new Date(slide.date).toLocaleString(culture, eventDateFormattingOptions);
				}
			} else {
				dateString = new Date(slide.date).toLocaleString(culture, dateFormattingOptions);
			}

			return (
				<SliderNewsItem
					key={index}
					heading={handleShyHeading({ heading: slide.header, shyHeading: slide.shyHeader })}
					text={slide.lead}
					dateString={dateString}
					link={{
						url: slide.url,
						name: slide.header,
					}}
				/>
			);
		});

		setNews(mappedSlides);
	};

	/****** API fetches ******************/
	const { data, isLoading } = useSWR(params, getTeasersSorted, {
		revalidateOnFocus: false,
	});

	useEffect(() => {
		let fromDate: string;

		if (category === 'Event') {
			// Limit to events that starts today or later
			fromDate = new Date().toISOString().split('T')[0] + 'T00:00:00.000Z';
		}

		setParams({
			rootKey: rootFolder?.key,
			documentTypes: ['articlePage'],
			categories: category
				? [
						{
							name: category,
						},
				  ]
				: [],
			teaserTags:
				teaserTags?.map((tag) => {
					return {
						id: tag.id,
						name: tag.name,
					};
				}) ?? [],
			limit: newsItemsAmount && newsItemsAmount > 0 ? newsItemsAmount : 12,
			host: host,
			direction: category === 'Event' ? 'ascending' : 'descending',
			from: fromDate,
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [content]);

	useEffect(() => {
		if (data) {
			sortAndSetNews(data);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	const sliderOptions = {
		slideArrowNext: dictionary.getValue('Accessibility.Slider.NextSlide', null, 'Next slide'),
		slideArrowPrevious: dictionary.getValue('Accessibility.Slider.PreviousSlide', null, 'Previous slide'),
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 20,
				pagination: false,
			},
			768: {
				slidesPerView: 2,
				spaceBetween: 40,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 40,
			},
			1248: {
				slidesPerView: 4,
				spaceBetween: 60,
			},
		},
	};
	return (
		<TeaserSlider
			heading={content.properties.header}
			slides={news}
			sliderOptions={sliderOptions}
			buttons={link ? (link as [UI.LinkBaseProps]) : []}
			isLoading={isLoading}
			backgroundColor={formattedBackgroundColor(backgroundColor)}
			spacing={mappedSpacing}
			fallbackText={fallbackText}
		/>
	);
};
