import cx from 'classnames';
import React from 'react';
import styles from './Quote.module.scss';

export interface QuoteProps extends UI.Text {
	style: 'primary' | 'secondary';
}

export const Quote: React.FC<QuoteProps> = ({ children, className, style = 'primary' }) => (
	<blockquote className={cx(styles.Quote, className, styles[`Quote___${style}`])}>{children}</blockquote>
);
