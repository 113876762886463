import React from 'react';
import { LinkButton, Button } from 'ui/components/1-atoms/Action';

export interface SliderButtonProps extends UI.LinkBaseProps {
	className?: string;
	text?: string;
	clickHandler?: () => void;
}

export const SliderButton: React.FC<SliderButtonProps> = ({ className, text, clickHandler, ...props }) => {
	const ButtonElement = props?.url ? LinkButton : Button;

	return (
		<ButtonElement
			className={className}
			aria-label={props?.name}
			style="secondary"
			onClick={clickHandler}
			{...props}
		>
			{text || props?.name}
		</ButtonElement>
	);
};
