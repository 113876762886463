import classNames from 'classnames';
import styles from './JobCard.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading';
import { Paragraph } from 'ui/components/1-atoms/Paragraph';
import { LinkButton } from 'ui/components/1-atoms/Action/LinkButton';
import { Caption } from 'ui/components/1-atoms/Caption';
import { Time } from 'ui/components/1-atoms/Time';

export interface JobProps {
	title: string;
	name: string;
	location: string;
	id: string;
	latestDate: string;
	dateTime: Date;
}

export interface JobCardDictionaryTexts {
	location: string;
	deadline: string;
}

export interface JobCardProps {
	className?: string;
	job?: JobProps;
	link?: Content.Link;
	dictionaryTexts: JobCardDictionaryTexts;
}

export const JobCard: React.FC<JobCardProps> = ({ className, job, link, dictionaryTexts }) => {
	return (
		<div className={classNames(styles.JobCard, className)}>
			{job && (
				<>
					<Heading headingLevel="h5" style="xs" className={styles.JobCard_heading}>
						{job.title}
					</Heading>
					<div>
						<div className={styles.JobCard_listInfo}>
							<Caption style="secondary" renderParagraph className={styles.JobCard_subheader}>
								{dictionaryTexts.location}
							</Caption>
							<Paragraph size="lg">{job?.location}</Paragraph>
						</div>
						<div className={styles.JobCard_listInfo_details}>
							<div>
								<Caption style="secondary" renderParagraph className={styles.JobCard_subheader}>
									{dictionaryTexts.deadline}
								</Caption>
								<Paragraph size="lg">
									<Time date={job.dateTime}>{job.latestDate}</Time>
								</Paragraph>
							</div>
							{link && (
								<LinkButton style="secondary" target="_blank" url={link.url}>
									{link.name}
								</LinkButton>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
};
