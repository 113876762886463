import cx from 'classnames';
import ArrowRight from 'ui/icons/mst_arrow-right.svg';
import { Icon } from 'ui/components/1-atoms/Media/Icon';
import { Link } from 'ui/components/1-atoms/Action/Link';
import styles from './LinkBar.module.scss';

export interface LinkBarProps extends UI.LinkBaseProps {
	children: React.ReactNode;
	ariaLabel?: string;
}

export const LinkBar: React.FC<LinkBarProps> = ({ children, ariaLabel, className, url, target, title }) => (
	<Link url={url} target={target} aria-label={ariaLabel} title={title} className={cx(styles.LinkBar, className)}>
		{children}
		<Icon className={styles.LinkBar_icon}>
			<ArrowRight />
		</Icon>
	</Link>
);
