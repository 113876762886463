import { spacingMapper } from 'helpers/mappers/spacingMapper';
import { widthMapper } from 'helpers/mappers/widthMapper';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Accordion } from 'ui/components/3-organisms/Accordion';
import { Container } from 'ui/components/4-habitats/Container';

export const AccordionFeature: React.FC<Content.Accordion> = ({ content }) => {
	const { accordionItems, width, spacing } = content?.properties ?? {};
	const router = useRouter();
	const [focusedAccordionItem, setFocusedAccordionItem] = useState<string | undefined>(router.asPath.split('#')[1]);

	const handleHashChange = (url: string) => {
		setFocusedAccordionItem(url.split('#')[1]);
	};

	useEffect(() => {
		router.events.on('hashChangeComplete', handleHashChange);

		return () => {
			router.events.off('hashChangeComplete', handleHashChange);
		};
	}, [router]);

	const mappedAccordionItems = accordionItems.map(({ content, settings }, index) => {
		const { title, anchorName, htmlContent } = content?.properties ?? {};
		const prunedAnchorName = anchorName?.replace('#', '');

		return {
			id: prunedAnchorName || `accordion-item-${index}`,
			title,
			htmlContent,
			openByDefault: settings?.properties?.openByDefault,
			focusByDefault: prunedAnchorName === focusedAccordionItem,
		};
	});

	return (
		<Container width={widthMapper(width)} spacingBottom={spacingMapper(spacing)} section>
			<Accordion {...content?.properties} accordionItems={mappedAccordionItems} />
		</Container>
	);
};
