const pad = (number: number) => (number < 10 ? `0${number}` : number);

const convertDate = (date) => new Date(date);

export const formatDate = (dateString: Date, format: string): string => {
	let formattedDateString = format;

	const jsDate = convertDate(dateString);

	const dd = pad(jsDate.getUTCDate()).toString();
	const mm = pad(jsDate.getUTCMonth() + 1).toString();
	const yyyy = jsDate.getUTCFullYear().toString();

	formattedDateString = formattedDateString.replace('dd', dd);
	formattedDateString = formattedDateString.replace('mm', mm);
	formattedDateString = formattedDateString.replace('yyyy', yyyy);

	return formattedDateString;
};

export const formatTime = (dateString: Date, format: string): string => {
	let formattedTimeString = format;

	const jsDate = convertDate(dateString);

	const hh = pad(jsDate.getHours()).toString();
	const mm = pad(jsDate.getMinutes()).toString();

	formattedTimeString = formattedTimeString.replace('hh', hh);
	formattedTimeString = formattedTimeString.replace('mm', mm);

	return formattedTimeString;
};

export const listViewPrettyDate = (date: Date, locale: Content.Culture, includeTime = false): string => {
	return date.toLocaleDateString(locale, {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		hour: includeTime ? 'numeric' : undefined,
		minute: includeTime ? 'numeric' : undefined,
	});
};

export const getLastDayOfMonth = (year: number, monthIndex: number): number => {
	return new Date(year, monthIndex + 1, 0).getDate();
};

export const getLatestTimestamp = (year: string, month: string): string => {
	if (!year) return undefined;

	const timestampMonth = month ? month : '12'; // Fallback to December if month is undefined or empty string

	const timestamp = `${year}-${timestampMonth}-${getLastDayOfMonth(
		Number(year),
		Number(timestampMonth) - 1,
	)}T23:59:59.789Z`;

	return timestamp;
};

export const getOldestTimestamp = (year: string, month: string): string => {
	if (!year) return undefined;

	const timestampMonth = month ? month : '01'; // Fallback to January if month is undefined or empty string

	const timestamp = `${year}-${timestampMonth}-01T00:00:00.789Z`;

	return timestamp;
};

/**
 * If necessary convert a timestamp like: '2001-07-01T00.00.00' to '2001-07-01T00:00:00'
 * @param stamp
 * @returns
 */
export const convertTimestampToDate = (stamp: string): Date => {
	const replacedStamp = stamp.replaceAll('.', ':');
	return new Date(replacedStamp);
};

type GetFormattedDateRangeParams = {
	startDate: string | Date;
	endDate: string | Date;
	culture: string;
	formattingOptions?: Intl.DateTimeFormatOptions;
};

/**
 * Get a formatted date range string from two timestamps
 * @returns Formatted date range string, e.g. '1. juli 2021, kl. 10:00 - 12:00' or '11. juni 2024 kl. 10.00 - 13. juni 2024 kl. 14.00
 */
export const getFormattedDateRange = ({
	startDate,
	endDate,
	culture,
	formattingOptions,
}: GetFormattedDateRangeParams): string => {
	if (!startDate || !endDate) {
		return '';
	}

	const options: Intl.DateTimeFormatOptions = formattingOptions
		? formattingOptions
		: {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				hour: '2-digit',
				minute: '2-digit',
		  };

	const parsedStartDate = typeof startDate === 'string' ? convertTimestampToDate(startDate) : startDate;
	const parsedEndDate = typeof endDate === 'string' ? convertTimestampToDate(endDate) : endDate;

	if (startDate === endDate) {
		return parsedStartDate.toLocaleString(culture, options);
	}

	if (parsedStartDate.getDate() === parsedEndDate.getDate()) {
		const dateAndStartTime = parsedStartDate.toLocaleString(culture, options);
		const endTime = parsedEndDate.toLocaleTimeString(culture, {
			hour: options?.hour ?? '2-digit',
			minute: options?.minute ?? '2-digit',
		});

		return `${dateAndStartTime} - ${endTime}`;
	}

	const startDateString = parsedStartDate.toLocaleString(culture, options);
	const endDateString = parsedEndDate.toLocaleString(culture, options);

	return `${startDateString} - ${endDateString}`;
};
