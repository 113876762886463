import cx from 'classnames';
import styles from './Logo.module.scss';
import { Link } from 'ui/components/1-atoms/Action/Link';

export interface LogoProps {
	className?: string;
	heading: string;
	logoUrl: string;
	linkUrl: string;
	size?: 'sm' | 'md';
	headerColorTheme?: UI.HeaderColorTheme;
}

export const Logo: React.FC<LogoProps> = ({
	className,
	heading,
	logoUrl,
	linkUrl,
	size = 'md',
	headerColorTheme = 'dark',
}) => {
	return (
		<Link
			className={cx(styles.Logo, styles[`Logo___${headerColorTheme}`], className)}
			url={linkUrl}
			title={heading}
		>
			<img className={cx(styles.Logo_asset, styles[`Logo_asset___${size}`])} src={logoUrl} alt={heading} />
		</Link>
	);
};
