import cx from 'classnames';
import styles from './BlurbCard.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading';
import { GridCell, GridCellProps } from 'ui/components/4-habitats/Grid/GridCell';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';
import { formatDate } from 'helpers/date/date';

export interface BlurbCardProps {
	className?: string;
	heading?: string;
	link: {
		url: string;
		target?: string;
		name?: string;
	};
	kicker?: string;
	text?: string;
	date?: Date;
	settings?: {
		desktopWidth?: GridCellProps['desktopWidth'];
		mobileWidth?: GridCellProps['mobileWidth'];
	};
}

export const BlurbCard: React.FC<BlurbCardProps> = ({ link, className, heading, kicker, text, settings, date }) => {
	const desktopWidth = settings?.desktopWidth ?? '';
	const mobileWidth = settings?.mobileWidth ?? '';

	return (
		<GridCell desktopWidth={desktopWidth} mobileWidth={mobileWidth}>
			<div className={cx(styles.BlurbCard, className)}>
				<Link className={styles.BlurbCard_link} url={link?.url} target={link?.target} title={link?.name}>
					<div className={styles.BlurbCard_textbox}>
						{kicker && <div className={styles.BlurbCard_kicker}>{kicker}</div>}
						<Heading headingLevel="h3">{heading}</Heading>
						{date && <div className={styles.BlurbCard_date}>{formatDate(date, 'dd.mm.yyyy')}</div>}
						<div className={styles.BlurbCard_text} dangerouslySetInnerHTML={{ __html: text }}></div>
					</div>
				</Link>
			</div>
		</GridCell>
	);
};
