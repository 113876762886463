export const inView = ({ callback, elm }: { callback: () => void; elm: HTMLElement }): void => {
	// If useragent doens't support scroll, then it is properly a Google Bot
	// Therefor all images should be rendered for SEO purposes
	const supportScroll = 'onscroll' in window && !/glebot/.test(navigator.userAgent);

	// If the useragent doesn't support IntersectionObserver, we need to render all images
	const supportIntersectionObserver = 'IntersectionObserver' in window;

	if (!supportScroll || !supportIntersectionObserver) return callback();

	const observer = new IntersectionObserver((entries, observer) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				callback();
				observer.disconnect();
			}
		});
	});

	observer.observe(elm);
};
