import styles from './RichText.module.scss';
import cx from 'classnames';
import { Caption } from 'ui/components/1-atoms/Caption';
import { Link } from 'ui/components/1-atoms/Action/Link';
import { Picture } from 'ui/components/1-atoms/Media/Picture';
import ReactHtmlParser from 'react-html-parser';

export interface RichTextProps {
	content: string;
	className?: string;
	onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
	forwardedRef?: React.RefObject<HTMLDivElement>;
}

export const RichText: React.FC<RichTextProps> = ({ className, content, onScroll, forwardedRef }) => {
	const parsedContent = ReactHtmlParser(content, {
		transform: (node, index) => {
			if (node.type === 'tag' && node.name === 'a') {
				// Show nested content in formatting tags like <strong>, <i>, etc.
				const linkNestedContent =
					node.parent?.children[0]?.children && node.parent?.children[0]?.children[0]?.children?.[0]?.data;
				const hasNestedContent =
					(node.parent?.children[0]?.children?.[0]?.name === 'strong' ||
						node.parent?.children[0]?.children?.[0]?.name === 'i') &&
					linkNestedContent;
				const boldLink = node.parent?.children[0]?.children?.[0]?.name === 'strong' && (
					<strong>{linkNestedContent}</strong>
				);
				const italicLink = node.parent?.children[0]?.children?.[0]?.name === 'i' && <i>{linkNestedContent}</i>;

				return (
					<Link key={index} url={node.attribs.href} {...node.attribs}>
						{node.children[0]?.data && !hasNestedContent && node.children[0]?.data}
						{hasNestedContent && (boldLink || italicLink)}
					</Link>
				);
			}

			if (node.type === 'tag' && node.name === 'img' && node.attribs.src) {
				// Mst.dk is just a dummy domain, as we only want relative URL's
				const src = new URL(node.attribs.src, 'http://mst.dk');

				// Remove query params provided from the CMS because the Picture component
				// will handle them instead
				src.search = '';

				return <Picture key={index} url={src.pathname} altText={node.attribs.alt} isCover={false} />;
			}

			if (node.type === 'tag' && node.name === 'figcaption' && node.children[0]?.data) {
				return <Caption key={index}>{node.children[0].data}</Caption>;
			}
		},
	});

	return (
		<div className={cx(styles.RichText, className)} onScroll={onScroll} ref={forwardedRef}>
			{parsedContent}
		</div>
	);
};
