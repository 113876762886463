import cx from 'classnames';
import styles from './FormfieldCheckbox.module.scss';
import { Label } from 'ui/components/1-atoms/Form/Label';
import { ChangeEvent } from 'react';
import { functionNotNull } from 'helpers/functionNotNull/functionNotNull';

export interface FormfieldCheckboxCallbackProps {
	id: string;
	name: string;
	checked: boolean;
	value: string;
}
export interface FormfieldCheckboxProps extends FormfieldCheckboxCallbackProps {
	className?: string;
	labelText: string;
	multiline?: boolean;
	fieldGroup?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register?: any;
	state?: UI.FormfieldStateBase;
	forwardedRef?: React.RefObject<HTMLInputElement>;
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
	onCheckedChanged?: (payload: FormfieldCheckboxCallbackProps) => void;
	onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const FormfieldCheckbox: React.FC<FormfieldCheckboxProps> = ({
	className,
	fieldGroup,
	register,
	state,
	id,
	labelText,
	multiline,
	name,
	checked,
	value,
	forwardedRef,
	onChange,
	onCheckedChanged,
	onBlur,
}) => {
	const { required, disabled, hasError } = state ?? {};
	const labelState = {
		isHidden: false,
		disabled: state?.disabled,
		required: state?.required && !fieldGroup,
		hasError: state?.hasError,
	};

	const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
		functionNotNull(onChange) && onChange(event);

		functionNotNull(onCheckedChanged) &&
			onCheckedChanged({
				checked: event.target.checked,
				id: id,
				name: name,
				value: value,
			});
	};

	return (
		<div
			className={cx(
				styles.FormfieldCheckbox,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
				{ [styles.FormfieldCheckbox___multiline]: multiline === true },
			)}
		>
			<input
				ref={forwardedRef}
				type="checkbox"
				className={cx(styles.FormfieldCheckbox_input, styles.Input___choice, {
					[styles.hasError]: hasError,
				})}
				name={name}
				id={id}
				checked={checked}
				disabled={disabled}
				value={value}
				onChange={handleChange}
				onBlur={onBlur}
				{...(register && { ...register(name, { required }) })}
			/>
			<Label id={id} className={styles.FormfieldCheckbox_label} state={labelState}>
				{labelText}{' '}
			</Label>
		</div>
	);
};
