import logger from 'helpers/logger';
import { capitalizeFirstLetter } from 'helpers/string/string';
import * as PageTypes from 'features/PageTypes';
import { NODE_ENV } from '../../_constants';

export const PageTypeFeature: React.FC<Content.Page> = (page) => {
	const production = NODE_ENV === 'production';
	const featureName = `${capitalizeFirstLetter(page.template ?? '')}PageFeature`;
	const PageTypeTemplate = PageTypes[featureName];

	if (!PageTypeTemplate) {
		const message = `PageType [${page.template}] at	[${page.url}] is missing a React Component. Add missing export '${featureName} ' from /features.`;
		logger.error(message);
		if (!production) {
			return <div>{message}</div>;
		} else {
			return null;
		}
	}

	return <PageTypeTemplate {...page} />;
};
