import styles from './Pagination.module.scss';
import cx from 'classnames';
import ReactPaginate from 'react-paginate';
import { useBreakpoint } from 'ui/hooks/useBreakpoint';

export interface PaginationProps {
	className?: string;
	pageCount: number;
	/**
	 * The initial page selected.
	 */
	initialPageIndex?: number;
	/**
	 * To override selected page with parent prop.
	 */
	forcePageIndex?: number;
	pageText: string;
	/** Returns the index og selected page. Means that what is displayed as "Page 1" will be index 0 */
	onPageChange: (selectedItem: { selected: number }) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
	className,
	pageCount,
	initialPageIndex,
	forcePageIndex,
	onPageChange,
	pageText,
}) => {
	const isTablet = useBreakpoint('md');
	const isDesktop = useBreakpoint('lg');

	const calcPageRangeDisplayed = (): number => {
		if (isDesktop) return 3;
		if (isTablet) return 2;
		return 1;
	};

	const calcMarginPageDisplayed = (): number => {
		if (isDesktop) return 3;
		if (isTablet) return 1;
		return 1;
	};

	return (
		<div className={cx(styles.Pagination_wrapper, className)}>
			<ReactPaginate
				containerClassName={styles.Pagination}
				pageClassName={styles.Pagination_item}
				pageLinkClassName={styles.Pagination_link}
				previousClassName={cx(styles.Pagination_prev, { [styles.Pagination_item___disabled]: pageCount === 1 })}
				previousLinkClassName={cx(styles.Pagination_link, styles.Pagination_prevLink)}
				previousLabel={<></>}
				nextClassName={cx(styles.Pagination_next, { [styles.Pagination_item___disabled]: pageCount === 1 })}
				nextLinkClassName={cx(styles.Pagination_link, styles.Pagination_nextLink)}
				nextLabel={<></>}
				activeLinkClassName={styles.Pagination_link___active}
				disabledClassName={styles.Pagination_item___disabled}
				breakClassName={styles.Pagination_break}
				breakLinkClassName={styles.Pagination_breakLink}
				breakLabel="..."
				onPageChange={onPageChange}
				pageCount={pageCount}
				renderOnZeroPageCount={null}
				initialPage={initialPageIndex}
				forcePage={forcePageIndex}
				ariaLabelBuilder={(pageIndex) => {
					return `${pageText} ${pageIndex}`;
				}}
				pageRangeDisplayed={calcPageRangeDisplayed()}
				marginPagesDisplayed={calcMarginPageDisplayed()}
			/>
		</div>
	);
};
