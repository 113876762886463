import { usePageContext } from 'application/contexts/PageContext';
import { DynamicModuleFeature } from 'features/Modules';
import { BreadcrumbFeature } from 'features/Modules/Breadcrumb';
import { convertTimestampToDate } from 'helpers/date/date';
import { handleShyHeading } from 'helpers/string/string';
import { ArticleDate } from 'ui/components/2-molecules/Article/ArticleDate';
import { Container } from 'ui/components/4-habitats/Container';
import { HeroContentPage } from 'ui/components/3-organisms/Hero/HeroContentPage';
import { Main } from 'ui/components/4-habitats/Main';
import { EventMeta } from 'ui/components/3-organisms/EventMeta';
import { getFormattedDateRange } from 'helpers/date/date';

export const ArticlePageFeature: React.FC<Content.ArticlePage> = ({ properties, culture }) => {
	const PageContext = usePageContext();
	const { themeSettings } = PageContext;
	const { lead, image, altPublishDate, pageSections, category, eventStart, eventEnd, eventInfo, eventSignupLink } =
		properties ?? {};

	const eventDate =
		category === 'Event' ? getFormattedDateRange({ startDate: eventStart, endDate: eventEnd, culture }) : null;
	const showAltPublishDate = altPublishDate && category !== 'Indholdsside' && category !== 'Event';

	const picture: UI.ArticlePicture =
		image?.length > 0
			? {
					image: {
						altText: image[0].content?.properties?.image?.alt ?? '',
						url: image[0].content?.properties?.image?.url,
					},
					caption: image[0].content?.properties?.caption ?? '',
			  }
			: null;
	return (
		<Main
			breadcrumb={<BreadcrumbFeature />}
			renderAsArticle={true}
			header={
				<Container width="fluid">
					<HeroContentPage
						heading={handleShyHeading(properties)}
						lead={lead}
						image={picture}
						theme={themeSettings?.heroAndBreadcrumbColorTheme}
					/>
				</Container>
			}
		>
			{showAltPublishDate && (
				<Container>
					<ArticleDate date={convertTimestampToDate(altPublishDate)} locale={culture} />
				</Container>
			)}
			{eventDate && (
				<Container width="sm" spacingBottom="sm">
					<EventMeta date={eventDate} info={eventInfo} link={eventSignupLink} />
				</Container>
			)}
			{pageSections.map((pageSection, index) => (
				<DynamicModuleFeature key={index} contentModule={pageSection} />
			))}
		</Main>
	);
};
