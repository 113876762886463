import { Container } from 'ui/components/4-habitats/Container';
import { NavigationCards, NavigationCardsProps } from 'ui/components/3-organisms/NavigationCards';
import { spacingMapper } from 'helpers/mappers/spacingMapper';

export const NavigationCardsFeature: React.FC<Content.NavigationCards> = ({ content }) => {
	const { backgroundColor, cards, columns, spacing } = content?.properties ?? {};
	const formattedBackgroundColor = backgroundColor?.toLowerCase() as NavigationCardsProps['backgroundColor'];

	const mappedCards =
		cards?.map((card) => {
			const { heading, image, links } = card?.content?.properties ?? {};

			return {
				...card?.content?.properties,
				image: {
					...image,
					altText: image?.alt ?? heading,
				},
				link: links?.length === 1 ? links[0] : null,
				linkList: links?.length > 1 ? links : null,
			};
		}) ?? [];

	return (
		<Container section width="fluid" spacingBottom={spacingMapper(spacing)}>
			<NavigationCards
				{...content?.properties}
				cards={mappedCards}
				layout={columns}
				backgroundColor={formattedBackgroundColor}
				linkType={'card'}
			/>
		</Container>
	);
};
