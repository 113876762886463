import cx from 'classnames';
import { ariaError } from 'helpers/form/ariaError/ariaError';
import { ariaLabel } from 'helpers/form/ariaLabel/ariaLabel';
import { ErrorMessage } from 'ui/components/1-atoms/Form/ErrorMessage';
import { Label } from 'ui/components/1-atoms/Form/Label';
import { useFormContext } from 'ui/contexts/FormContext';
import styles from './FormfieldSelect.module.scss';

export type FormfieldSelectOption = {
	value: string;
	text: string;
	disabled?: boolean;
	hidden?: boolean;
	selected?: boolean;
};

export interface FormfieldSelectProps extends UI.FormfieldBaseProps {
	size?: number;
	style?: UI.FormfieldStyle;
	multiple?: boolean;
	options: FormfieldSelectOption[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	register?: any;
	rules?: Record<string, unknown>;
	defaultValue?: string;
}

export const FormfieldSelect: React.FC<FormfieldSelectProps> = ({
	className,
	register,
	rules,
	errorMessage,
	state,
	id,
	label,
	options,
	name,
	multiple,
	size,
	style = 'bordered',
	defaultValue,
}) => {
	const { required, disabled, hasError } = state ?? {};
	const dictionary = useFormContext();

	return (
		<div
			className={cx(
				styles.FormfieldSelect,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
			)}
		>
			<Label id={id} className={styles.FormfieldSelect_label} state={state}>
				{label}
			</Label>
			<div className={cx(styles.FormfieldSelect_wrapper, { [styles.isMultiple]: multiple === true })}>
				<select
					name={name}
					id={id}
					className={cx(styles.FormfieldSelect_element, styles[`FormfieldSelect_element___${style}`])}
					aria-label={ariaLabel(state, label)}
					aria-describedby={ariaError(state, name, id)}
					multiple={multiple}
					size={size}
					disabled={disabled}
					defaultValue={defaultValue}
					{...(register &&
						register(name, {
							required: {
								value: required,
								message: dictionary?.requiredErrorMessage ?? 'This field is required',
							},
							...rules,
						}))}
				>
					{options.map((option, index) => (
						<option
							value={option.value}
							selected={option.selected}
							disabled={option.disabled}
							hidden={option.hidden}
							key={index}
						>
							{option.text}
						</option>
					))}
				</select>
			</div>
			{hasError && <ErrorMessage id={`${id}-error`}>{errorMessage}</ErrorMessage>}
		</div>
	);
};
