import React, { createContext, useContext } from 'react';

interface FormContextProviderProps {
	children?: React.ReactNode;
	dictionary: UI.FormDictionary;
}

export const FormContext = createContext(null);

export const FormContextProvider: React.FC<FormContextProviderProps> = (props) => {
	const { children, dictionary } = props;

	return <FormContext.Provider value={dictionary}>{children}</FormContext.Provider>;
};

export const useFormContext = (): UI.FormDictionary => {
	return useContext(FormContext);
};
