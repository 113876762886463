import { Container } from 'ui/components/4-habitats/Container';
import { LinkBars } from 'ui/components/3-organisms/LinkBars';
import { spacingMapper } from 'helpers/mappers/spacingMapper';

export const LinkBarsFeature: React.FC<Content.LinkBars> = ({ content }) => {
	const { spacing } = content?.properties ?? {};

	return (
		<Container section width="std" spacingBottom={spacingMapper(spacing)}>
			<LinkBars {...content?.properties} />
		</Container>
	);
};
