import cx from 'classnames';
import React from 'react';
import styles from './ContactPerson.module.scss';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';
import { Picture } from 'ui/components/1-atoms/Media/Picture';

export interface ContactPersonProps {
	className?: string;
	persons: ContactPersonItemProps[];
	dictionary?: ContactPersonDirctionayProps;
}

export interface ContactPersonItemProps {
	heading?: string;
	postionAndName: string;
	location?: string;
	phone?: string;
	email?: string;
	initials: string;
	image?: UI.Image;
	dictionary?: ContactPersonDirctionayProps;
}

export interface ContactPersonDirctionayProps {
	phoneLabel: string;
	emailLabel: string;
	imageAltText: string;
}

export const ContactPerson: React.FC<ContactPersonProps> = ({ dictionary, persons }) => {
	return (
		<>
			{persons ? (
				<article className={styles.ContactPerson}>
					{persons.map((person, index) => (
						<ContactPersonItem key={index} dictionary={dictionary} {...person} />
					))}
				</article>
			) : null}
		</>
	);
};

export const ContactPersonItem: React.FC<ContactPersonItemProps> = ({
	heading,
	postionAndName,
	location,
	phone,
	email,
	initials,
	image,
	dictionary,
}) => {
	return (
		<div className={styles.ContactPerson_item}>
			{image ? (
				<div className={styles.ContactPerson_profile}>
					<Picture
						url={image.url}
						altText={`${dictionary?.imageAltText} ${postionAndName}`}
						className={styles.ContactPerson_profile}
					/>
				</div>
			) : (
				<div className={cx(styles.ContactPerson_initials, styles.ContactPerson_profile)}>
					<p>{initials}</p>
				</div>
			)}
			<div className={styles.ContactPerson_information}>
				{heading && (
					<p className={styles.ContactPerson_text}>
						<strong>{heading}</strong>
					</p>
				)}
				{postionAndName && <p className={styles.ContactPerson_text}>{postionAndName}</p>}
				{location && <p className={styles.ContactPerson_text}>{location}</p>}
				{phone && (
					<p className={styles.ContactPerson_text}>
						{dictionary?.phoneLabel} <Link url={`tel:${phone}`}> {phone}</Link>
					</p>
				)}
				{email && (
					<p className={styles.ContactPerson_text}>
						{dictionary?.emailLabel} <Link url={`mailto:${email}`}> {email}</Link>
					</p>
				)}
			</div>
		</div>
	);
};
