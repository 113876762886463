import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './MobileNavigation.module.scss';
import { Button } from 'ui/components/1-atoms/Action';
import { Hamburger } from 'ui/components/1-atoms/Hamburger';
import { ServiceNavigation } from '../ServiceNavigation';
import { SubNavigation } from '../SubNavigation';

export interface MobileNavigationProps extends UI.Navigation {
	className?: string;
	toggleMenuButtonText: string;
	openDefault?: boolean; // This prop is mostly here for Storybook purposes
	isSearchPageIncluded?: boolean;
}

export const MobileNavigation: React.FC<MobileNavigationProps> = ({
	className,
	mainNavigation,
	serviceNavigation,
	currentPageId,
	toggleMenuButtonText,
	openDefault = false,
	colorTheme = 'dark',
	isSearchPageIncluded,
}) => {
	const wrapperRef = useRef(null);
	const [isOpen, setIsOpen] = useState(openDefault);
	const [pageReady, setPageReady] = useState(false);

	useEffect(() => {
		const onPageLoad = () => {
			setPageReady(true);
		};

		// Check if the page has already loaded
		if (document.readyState === 'complete') {
			onPageLoad();
		} else {
			window.addEventListener('load', onPageLoad);
			// Remove the event listener when component unmounts
			return () => window.removeEventListener('load', onPageLoad);
		}
	}, []);

	useEffect(() => {
		if (isOpen) disableBodyScroll(wrapperRef.current);
		if (!isOpen) enableBodyScroll(wrapperRef.current);

		// 	In Safari, enableBodyScroll() (from the 'body-scroll-lock' library) will add
		// { position: fixed; top: 0; left: 0; } to <body> to prevent scrolling. In some cases this will cause the
		// menu to overflow the viewport. As a workaround, we set right: 0; on body when the menu is
		// open to prevent the overflow. In the future, we might consider using a different approach for
		// body scroll locking to eliminate this issue. See comments in PBI 250533 for more details.
		document.body.style.right = isOpen ? '0	' : '';

		return () => clearAllBodyScrollLocks();
	}, [isOpen]);

	const hideNavigation = () => setIsOpen(false);
	const toggleButtonStyle = colorTheme === 'dark' ? 'secondary-negative' : 'primary';

	return (
		<div className={cx(styles.MobileNavigation, className)}>
			{pageReady && (
				<Button
					className={cx(styles.MobileNavigation_toggle, {
						[styles.MobileNavigation_toggle___isOpen]: isOpen,
					})}
					style={toggleButtonStyle}
					onClick={(e) => {
						const element = e.target as HTMLElement;
						element.blur();
						setIsOpen(!isOpen);
					}}
					ariaExpanded={isOpen}
					ariaControls="MobileNavigation"
					aria-label={toggleMenuButtonText}
				>
					<Hamburger isActive={isOpen} />
					<span className={styles.MobileNavigation_toggle_text}>{toggleMenuButtonText}</span>
				</Button>
			)}
			<CSSTransition nodeRef={wrapperRef} appear={true} classNames={{ ...styles }} timeout={300} in={isOpen}>
				<nav
					id="MobileNavigation"
					className={cx(styles.MobileNavigation_wrapper, styles[`MobileNavigation_wrapper___${colorTheme}`])}
					ref={wrapperRef}
					aria-label={mainNavigation?.ariaLabel}
				>
					{/* NOTE: Prevents the mobile nav from being accessible with keyboard when closed */}
					{isOpen && (
						<>
							<ServiceNavigation
								className={cx(styles.MobileNavigation_serviceNavigation, {
									[styles.MobileNavigation_serviceNavigation___noSearchBox]: !isSearchPageIncluded,
								})}
								{...serviceNavigation}
								handleSearch={(searchValue) => {
									serviceNavigation?.handleSearch(searchValue) && hideNavigation();
								}}
								isMobile
								colorTheme={colorTheme}
								isSearchPageIncluded={isSearchPageIncluded}
							/>
							<SubNavigation
								isMobile={true}
								className={styles.MobileNavigation_menu}
								currentPageId={currentPageId}
								items={mainNavigation?.items}
								navId={1}
								hideNavigation={hideNavigation}
								previousButtonText={mainNavigation?.previousButtonText}
								previousButtonAriaLabel={mainNavigation?.previousButtonAriaLabel}
								colorTheme={colorTheme}
							/>
						</>
					)}
				</nav>
			</CSSTransition>
		</div>
	);
};
