import cx from 'classnames';
import styles from './Button.module.scss';

export interface ButtonProps {
	children: React.ReactNode;
	style?: UI.ButtonStyle;
	size?: UI.ButtonSize;
	title?: string;
	id?: string;
	className?: string;
	type?: 'button' | 'submit' | 'reset';
	disabled?: boolean;
	ariaExpanded?: boolean;
	ariaControls?: string;
	onClick?: (event?: React.MouseEvent) => void;
	ariaLabel?: string;
}

export const Button: React.FC<ButtonProps> = ({
	children,
	style,
	title,
	id,
	className,
	type = 'button',
	size = 'md',
	disabled,
	onClick,
	ariaLabel,
	ariaExpanded,
	ariaControls,
}) => (
	<button
		id={id}
		type={type !== 'button' ? type : null}
		title={title}
		className={cx(styles.Button, styles[`Button___${style}`], styles[`Button___${size}`], className)}
		disabled={disabled}
		aria-disabled={disabled}
		aria-label={ariaLabel}
		onClick={onClick}
		aria-controls={ariaControls}
		aria-expanded={ariaExpanded}
	>
		{children}
	</button>
);
