import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { usePageContext } from 'application/contexts/PageContext';
import { handleShyHeading } from 'helpers/string/string';
import { Hero, HeroVideoProps, VideoType } from 'ui/components/3-organisms/Hero';
import { HeroFocus } from 'ui/components/2-molecules/HeroFocus';
import { HeroShortcuts } from 'ui/components/2-molecules/HeroShortcuts';

export const HeroFeature: React.FC<Content.Hero> = ({ content }) => {
	const PageContext = usePageContext();
	const { themeSettings } = PageContext;
	const dictionary = useDictionaryContext();
	const { colorTheme, image, video, focusArea, lead, link } = content?.properties ?? {};

	const videos: HeroVideoProps[] = video
		? [
				{
					src: video.url,
					type: video.extension as VideoType,
				},
		  ]
		: [];

	const focus = () => {
		if (!focusArea || focusArea.length === 0) return null;

		if (focusArea[0]?.documentType === 'heroFocus') {
			const { description, image, label, link, themeDark } = focusArea[0].content?.properties ?? {};

			return (
				<HeroFocus
					kicker={label}
					description={description}
					image={{ url: image.url, altText: '' }}
					link={link}
					theme={themeDark ? 'dark' : 'light'}
				/>
			);
		} else if (focusArea[0]?.documentType === 'heroShortcuts') {
			const { links, colorTheme } = focusArea[0].content?.properties ?? {};
			return (
				<HeroShortcuts
					heading={dictionary.getValue('Hero.Shortcuts.Heading', null)}
					links={links}
					colorTheme={colorTheme}
				/>
			);
		}
	};

	return (
		<Hero
			videos={videos}
			heroBox={focus()}
			imageUrl={image?.url}
			theme={colorTheme || themeSettings?.heroAndBreadcrumbColorTheme}
			heading={handleShyHeading(content?.properties)}
			link={link}
			lead={lead}
		/>
	);
};
