import { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Button } from 'ui/components/1-atoms/Action';
import { FormfieldString, FormfieldCheckbox } from 'ui/components/2-molecules/Form';
import { Form } from '../Form';

type FormData = {
	email: string;
};

export interface NewsletterProps {
	emailPlaceholder?: string;
	emailLabel: string;
	signupLabel: string;
	newsletterTypes?: string[];
}

export const Newsletter: React.FC<NewsletterProps> = ({
	emailLabel,
	emailPlaceholder,
	signupLabel,
	newsletterTypes,
}) => {
	const { register, handleSubmit } = useForm<FormData>();
	const onSubmit = (data) => {
		alert(`Thank for submitting, ${JSON.stringify(data)}`);
	};

	return (
		<Form onSubmit={handleSubmit(onSubmit)} getErrorTxtFromSummary={null}>
			<FormfieldString
				id="email"
				name="email"
				label={emailLabel}
				type="email"
				register={register}
				placeholder={emailPlaceholder}
				className="u-bottom-margin--md"
			/>
			{newsletterTypes ? renderNewsletterTypes(newsletterTypes) : null}
			<Button type="submit" style="primary">
				{signupLabel}
			</Button>
		</Form>
	);
};

function renderNewsletterTypes(newsletterTypes: string[]): ReactElement<unknown, string> {
	return (
		<div className="u-bottom-margin--xl">
			{newsletterTypes.map((renderNewsletterType, index) => {
				return (
					<FormfieldCheckbox
						id={renderNewsletterType}
						key={index}
						name={renderNewsletterType}
						labelText={renderNewsletterType}
						value={''}
						checked={false}
					/>
				);
			})}
		</div>
	);
}
