export const pageCategoryToSubjectDocType = (category: Content.ContentPageCategory): Content.SubjectsDocumentTypes => {
	switch (category) {
		case 'Annoncering':
			return 'subjectSettingAd';
		case 'Nyhed':
			return 'subjectSettingNews';
		case 'Publikation':
			return 'subjectSettingPublication';
		case 'Udbud':
			return 'subjectSettingTender';
		case 'Event':
			return 'subjectSettingEvent';
		default:
			return '';
	}
};

export const mapSubjects = (subjects: Content.ContentOverviewSubjects[]): Models.Settings.SubjectSetting[] => {
	return subjects.map((elm) => {
		const { subjectName, ubivoxListId } = elm.Properties ?? {};
		return {
			name: elm.name,
			id: elm.id,
			subjectName: subjectName,
			ubivoxListId: ubivoxListId > 0 ? ubivoxListId : null,
			url: elm.url,
		} as Models.Settings.SubjectSetting;
	});
};
