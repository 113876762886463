import { useEffect, useState } from 'react';
import { Container } from 'ui/components/4-habitats/Container';
import { DocumentList } from 'ui/components/3-organisms/DocumentList';

export const DocumentListFeature: React.FC<Content.DocumentList> = ({ content }) => {
	const { header, documents } = content.properties;
	const [mediaDocuments, setMediaDocuments] = useState([]);

	useEffect(() => {
		setMediaDocuments(
			documents.map((doc) => {
				return {
					id: doc.id,
					name: doc.name,
					url: doc.url,
					extension: doc.extension,
				} as Content.MediaFile;
			}),
		);
	}, [documents]);

	return documents ? (
		<Container spacingBottom="sm" section width="sm">
			<DocumentList documents={mediaDocuments} header={header} />
		</Container>
	) : null;
};
