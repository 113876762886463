import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { spacingMapper } from 'helpers/mappers/spacingMapper';
import { CampaignTeaser } from 'ui/components/3-organisms/CampaignTeaser';

export const CampaignTeaserFeature: React.FC<Content.CampaignTeaser> = ({ content }) => {
	const dictionary = useDictionaryContext();

	const sliderOptions = {
		slideArrowNext: dictionary.getValue('Accessibility.Slider.NextSlide', null, 'Next slide'),
		slideArrowPrevious: dictionary.getValue('Accessibility.Slider.PreviousSlide', null, 'Previous slide'),
		breakpoints: null,
	};

	const { spacing, colorTheme, campaignTeaserItems } = content?.properties ?? {};

	const featuredCampaignItems = campaignTeaserItems?.slice(0, 2)?.map((slide) => ({
		...slide?.content.properties,
		image: {
			url: slide?.content.properties?.image?.url,
			altText: slide?.content.properties?.image?.alt,
		},
	}));

	const slides = campaignTeaserItems?.slice(2)?.map((slide) => ({
		...slide?.content.properties,
		image: {
			url: slide?.content.properties?.image?.url,
			altText: slide?.content.properties?.image?.alt,
		},
	}));

	return (
		<CampaignTeaser
			{...content?.properties}
			featuredCampaignItems={featuredCampaignItems}
			slides={slides}
			sliderOptions={sliderOptions}
			spacingBottom={spacingMapper(spacing)}
			colorTheme={colorTheme}
		/>
	);
};
