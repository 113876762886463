import { useState } from 'react';
import styles from './SearchBox.module.scss';
import { Button } from 'ui/components/1-atoms/Action';
import { Container } from 'ui/components/4-habitats/Container';
import { Form } from '../Form';
import { SearchFieldSimple } from '../SearchFieldSimple';

export interface SearchBoxProps {
	closeButtonText: string;
	searchButtonText: string;
	searchInputPlaceholder: string;
	handleClose: () => void;
	handleSearch: (searchValue: string) => void;
	isOpen: boolean;
	forwardedRef?: React.RefObject<HTMLInputElement>;
}

export const SearchBox: React.FC<SearchBoxProps> = ({
	closeButtonText,
	searchButtonText,
	searchInputPlaceholder,
	handleClose,
	handleSearch,
	forwardedRef,
}) => {
	const [searchValue, setSearchValue] = useState('');

	return (
		<Container width="fluid" className={styles.SearchBox}>
			<Container width="xl" className={styles.SearchBox_closeButtonWrapper}>
				<Button size={'none'} onClick={handleClose} style={'link'}>
					{closeButtonText}
				</Button>
			</Container>
			<Container width="std" className={styles.SearchBox_content}>
				<Form
					className={styles.SearchBox_form}
					onSubmit={(e) => {
						e.preventDefault();
						handleSearch(searchValue);
					}}
					getErrorTxtFromSummary={null}
				>
					<SearchFieldSimple
						className={styles.SearchBox_searchField}
						id={'SearchBox'}
						name={'search'}
						label={searchButtonText}
						buttonText={searchButtonText}
						placeholder={searchInputPlaceholder}
						inputStyle={'borderlessWhite'}
						handleChange={(value) => setSearchValue(value)}
						disabled={searchValue.length === 0}
						forwardedRef={forwardedRef}
					/>
				</Form>
			</Container>
		</Container>
	);
};
