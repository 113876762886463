import cx from 'classnames';
import SearchIcon from 'ui/icons/icon-search.svg';
import styles from './SearchFieldSimple.module.scss';
import { Button } from 'ui/components/1-atoms/Action';
import { Icon } from 'ui/components/1-atoms/Media';
import { FormfieldString } from 'ui/components/2-molecules/Form';

export interface SearchFieldSimpleProps {
	className?: string;
	buttonText: string;
	id: string;
	name: string;
	label?: string;
	hiddenLabel?: boolean;
	placeholder?: string;
	inputStyle: UI.FormfieldStyle;
	handleChange?: (value: string) => void;
	disabled?: boolean;
	forwardedRef?: React.RefObject<HTMLInputElement>;
}

export const SearchFieldSimple: React.FC<SearchFieldSimpleProps> = ({
	className,
	buttonText,
	label,
	id,
	name,
	placeholder,
	inputStyle,
	handleChange,
	disabled,
	forwardedRef,
}) => {
	return (
		<div className={cx(styles.SearchFieldSimple, className)}>
			<FormfieldString
				type="search"
				id={id}
				name={name}
				label={label}
				style={inputStyle}
				state={{ isHidden: true }}
				placeholder={placeholder}
				className={styles.SearchFieldSimple_input}
				handleChange={handleChange}
				forwardedRef={forwardedRef}
			/>
			<Button
				type="submit"
				style="primary"
				className={styles.SearchFieldSimple_button}
				disabled={disabled}
				aria-label={buttonText}
			>
				{buttonText}
				<Icon className={styles.SearchFieldSimple_searchIcon}>
					<SearchIcon />
				</Icon>
			</Button>
		</div>
	);
};
