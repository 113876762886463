import cx from 'classnames';
import { useEffect, useState } from 'react';
import { SubNavigationItem } from './SubNavigationItem';
import AnimateHeight from 'react-animate-height';
import styles from './SubNavigation.module.scss';

export interface SubNavigationProps {
	items: UI.NavigationItem[];
	itemHeader?: UI.NavigationItem;
	className?: string;
	navId: number;
	currentPageId: number;
	isMobile?: boolean;
	hideNavigation: () => void;
	searchNavigationLink?: string;
	previousButtonText: string;
	previousButtonAriaLabel: string;
	colorTheme?: string;
}

export const SubNavigation: React.FC<SubNavigationProps> = ({
	navId,
	currentPageId,
	items,
	itemHeader,
	className,
	isMobile,
	hideNavigation,
	previousButtonText,
	previousButtonAriaLabel,
	colorTheme = 'dark',
}) => {
	const [currentSubNav, setCurrentSubNav] = useState({ id: navId, navLevel: 0 });
	const [height, setHeight] = useState<'auto' | number>('auto');
	const [duration, setDuration] = useState(0);

	useEffect(() => {
		let timeout = null;
		timeout = setTimeout(() => {
			setDuration(300);
		}, 300);

		return () => clearTimeout(timeout);
	}, []);

	useEffect(() => {
		setCurrentSubNav({ id: navId, navLevel: 0 });
	}, [items, navId]);

	return (
		<AnimateHeight
			className={cx(styles.SubNavigation, styles[`SubNavigation___${colorTheme}`], className)}
			height={height}
			duration={duration}
		>
			<div
				className={cx(styles.SubNavigation_wrapper, className)}
				style={{
					transform: `translateX(${currentSubNav.navLevel * -100}%)`,
					transitionDuration: `${duration}ms`,
				}}
			>
				<SubNavigationItem
					setHeight={setHeight}
					navLevel={0}
					currentPageId={currentPageId}
					navId={navId}
					parentId={navId}
					currentSubNav={currentSubNav}
					setCurrentSubNav={setCurrentSubNav}
					items={items}
					itemHeader={itemHeader}
					isMobile={isMobile}
					closeMenu={hideNavigation}
					previousButtonText={previousButtonText}
					previousButtonAriaLabel={previousButtonAriaLabel}
				/>
			</div>
		</AnimateHeight>
	);
};
