import cx from 'classnames';
import styles from './Embed.module.scss';
import React, { useEffect, useRef } from 'react';
import { Heading } from 'ui/components/1-atoms/Heading';
import { Container, ContainerWidth } from 'ui/components/4-habitats/Container';

export interface EmbedProps {
	className?: string;
	title?: string;
	embedCode: string;
	embedSize?: ContainerWidth;
	disableAspectRatio?: boolean;
}

export const Embed: React.FC<EmbedProps> = ({ className, title, embedCode, disableAspectRatio, embedSize = 'std' }) => {
	const embedContainer = useRef<HTMLDivElement | null>(null);
	const containInlineScript = () => {
		const result = embedCode.includes('<script');
		return result;
	};

	// Apparently scripts can contain eventlistener for on load. That event is not fired when the page is routed to. Only on reload.
	const containsOnLoadEvent = () => {
		const result = embedCode.includes(`window.addEventListener('load'`);
		return result;
	};

	// Convert inline script to script object & append to DOM
	useEffect(() => {
		if (containInlineScript()) {
			const fragment = document.createRange().createContextualFragment(embedCode);
			const currentContainer = disableAspectRatio
				? embedContainer.current?.lastChild
				: embedContainer.current?.lastChild.firstChild;
			currentContainer?.appendChild(fragment);
		}

		// If embedScript contains onLoad eventlistener and document is allready complete, that event is not fired.
		// Therefore we are forced to reload the page for the embedScript to function as expected by the editor.
		// If we dispatch the load-event ourselves, the risk is that the embed-script will subscribe to the load-event multiple times witohout ever unsubscribing,
		// when user navigates back and forth to the same page.
		if (document.readyState === 'complete' && containsOnLoadEvent()) {
			window.location.reload();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [embedCode]);

	return (
		<Container section width={embedSize} spacingBottom={'sm'}>
			<div ref={embedContainer} className={cx(styles.Embed, 'u-bottom-padding--md', className)}>
				{title?.length !== 0 && (
					<Heading headingLevel="h2" style="md" className={styles.Embed_title}>
						{title}
					</Heading>
				)}
				{disableAspectRatio ? (
					<div
						className={styles.Embed_content}
						// Insert HTML string when there's
						// no script tags
						{...(!containInlineScript() && {
							dangerouslySetInnerHTML: {
								__html: embedCode,
							},
						})}
					/>
				) : (
					<div className={styles.Embed_media}>
						<div
							className={styles.Embed_mediabox}
							{...(!containInlineScript() && {
								dangerouslySetInnerHTML: {
									__html: embedCode,
								},
							})}
						/>
					</div>
				)}
			</div>
		</Container>
	);
};
