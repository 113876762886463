import { Container } from 'ui/components/4-habitats/Container';
import { FactBox, FactBoxColorTheme } from 'ui/components/3-organisms/FactBox';

export const FactBoxFeature: React.FC<Content.FactBox> = ({ content }) => {
	const { image, colorTheme } = content?.properties ?? {};
	const colorThemeLowerCase = colorTheme?.toLowerCase() ?? 'light';

	return (
		<Container width="md" section>
			<FactBox
				{...content?.properties}
				image={
					image?.url
						? {
								url: image.url,
								altText: image?.alt ?? '',
						  }
						: null
				}
				colorTheme={colorThemeLowerCase as FactBoxColorTheme}
			/>
		</Container>
	);
};
