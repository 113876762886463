import cx from 'classnames';
import styles from './Icon.module.scss';

export interface IconProps {
	title?: string;
	className?: string;
	children: React.ReactNode;
	ariaHidden?: boolean;
	style?: 'circle';
	size?: 'sm' | 'md' | 'lg' | 'xl';
}

export const Icon: React.FC<IconProps> = ({ title, style, size = 'md', className, ariaHidden = true, children }) => (
	<span
		aria-label={title}
		className={cx(styles.Icon, styles[`Icon___${style}`], styles[`Icon___${size}`], className)}
		aria-hidden={ariaHidden}
	>
		{children}
	</span>
);
