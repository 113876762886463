import cx from 'classnames';
import React from 'react';
import { Heading } from 'ui/components/1-atoms/Heading';
import styles from './FactBox.module.scss';
import { Picture } from 'ui/components/1-atoms/Media';
import { RichText } from 'ui/components/1-atoms/RichText';

export type FactBoxColorTheme = 'light' | 'dark';

export interface FactBoxProps {
	className?: string;
	heading: string;
	text?: string;
	image?: UI.Image;
	colorTheme: FactBoxColorTheme;
	backgroundColor?: string;
}

export const FactBox: React.FC<FactBoxProps> = ({
	className,
	heading,
	text,
	image,
	colorTheme = 'light',
	backgroundColor,
}) => {
	return (
		<div
			className={cx(styles.FactBox, styles[`FactBox___${colorTheme}`], className)}
			style={{ backgroundColor: backgroundColor }}
		>
			<Heading className={styles.FactBox_heading} headingLevel="h2" style="sm">
				{heading}
			</Heading>
			{text && <RichText content={text} />}
			{image && (
				<Picture className={styles.FactBox_image} url={image.url} altText={image.altText} isCover={true} />
			)}
		</div>
	);
};
