import cx from 'classnames';
import { cludoRequiredIdsAndClasses } from 'helpers/cludo';
import { useEffect, useRef } from 'react';
import { Container } from 'ui/components/4-habitats/Container';
import { SearchFilter } from '../SearchFilter';
import styles from './CludoSearch.module.scss';
import { CludoSearchFilterSelect } from './CludoSearchFilterSelect';
import { decodeQueryParamString } from 'helpers/string/string';
import { HeroCanvasPageProps, HeroCanvasPage } from '../Hero';
import { SearchResultList } from '../Lists';

export interface CludoSearchProps {
	className?: string;
	hero: HeroCanvasPageProps;
	searchQuery: {
		isLoading: boolean;
		queryId: string;
		currentCategory: string;
	};
	dictionary: {
		categoryFilter: {
			toggleButtonText: string;
			dropdownAriaLabel: string;
		};
		searchFilter: {
			heading: string;
			clearSearchBtn: string;
			submitBtn: string;
		};
		noScriptTxt: string;
	};
}

export const CludoSearch: React.FC<CludoSearchProps> = ({ className, hero, searchQuery, dictionary }) => {
	const {
		formId,
		searchInputId,
		searchInputContainerId,
		searchButtonId,
		searchResultsId,
		searchResultsClass,
		searchResultCountClass,
		searchDidYouMeanClass,
	} = cludoRequiredIdsAndClasses;
	const searchFieldRef = useRef<HTMLInputElement>(null);

	// Trim the search string for input field
	useEffect(() => {
		if (searchFieldRef.current?.value.length !== 0)
			searchFieldRef.current.value = decodeQueryParamString(searchFieldRef.current?.value);
	});

	const searchFilterProps = {
		id: searchInputContainerId,
		heading: dictionary?.searchFilter?.heading,
		searchField: {
			id: searchInputId,
			name: 'search',
			placeholder: 'Search',
			value: null,
			onChange: null,
			handleSearchStringChanged: null,
		},
		clearSearchBtn: {
			text: dictionary?.searchFilter?.clearSearchBtn,
			onClick: () => {
				searchFieldRef.current.value = '';
				searchFieldRef.current.focus();
			},
		},
		forwardedFormfieldStringRef: searchFieldRef,
		submitBtn: { text: dictionary?.searchFilter?.submitBtn, onClick: null },
		cludoSearch: {
			formId,
			searchButtonId,
		},
		onGetErrortext: null,
	};

	return (
		<div id={searchResultsId} className={cx(styles.CludoSearch, className)}>
			<HeroCanvasPage {...hero} searchFilter={searchFilterProps && <SearchFilter {...searchFilterProps} />} />
			<Container>
				<noscript>
					<p>
						<em>{dictionary.noScriptTxt}</em>
					</p>
				</noscript>
				<SearchResultList
					id={'search-results-list'}
					className={cx(styles.CludoSearch_resultsList, {
						[styles.CludoSearch_resultsList___isActive]: searchQuery.queryId ? true : false,
					})}
					heading={<div className={searchResultCountClass} />}
					isLoading={searchQuery.isLoading}
					filter={
						<div className={styles.CludoSearch_filter}>
							<CludoSearchFilterSelect
								id="search-filter"
								name="search-filter"
								toggleButtonText={
									searchQuery.currentCategory ?? dictionary?.categoryFilter?.toggleButtonText
								}
								style="bordered"
								queryId={searchQuery.queryId}
								dropdownAriaLabel={dictionary?.categoryFilter?.dropdownAriaLabel}
							/>
						</div>
					}
				>
					<div className={searchDidYouMeanClass} />
					<div className={cx(searchResultsClass, styles.CludoSearch_results)} />
				</SearchResultList>
			</Container>
		</div>
	);
};
