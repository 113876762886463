import cx from 'classnames';
import styles from './Spinner.module.scss';

export interface SpinnerProps {
	size?: 'small' | 'large';
	style?: 'slow';
	color?: 'light' | 'dark';
}

export const Spinner: React.FC<SpinnerProps> = ({ size, style, color = 'dark' }) => (
	<div className={styles.Spinner}>
		<div className={cx(styles.Spinner_screenReader, styles.Spinner_loadingMsg)} role="alert">
			loading...
		</div>
		<div
			className={cx(
				styles.Spinner_element,
				size && styles[`Spinner_element___size-${size}`],
				style && styles[`Spinner_element___style-${style}`],
				color && styles[`Spinner_element___color-${color}`],
			)}
			aria-hidden="true"
		></div>
	</div>
);
