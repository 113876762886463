import { Container } from 'ui/components/4-habitats/Container';
import { NavigationCardProps } from 'ui/components/2-molecules/Card/NavigationCard';
import { NavigationCards, NavigationCardsProps } from 'ui/components/3-organisms/NavigationCards';
import { spacingMapper } from 'helpers/mappers/spacingMapper';

export const NavigationCardsIconsFeature: React.FC<Content.NavigationCardsIcons> = ({ content }) => {
	const { backgroundColor, cards, columns, spacing } = content?.properties ?? {};
	const iconSize = content?.properties?.iconSize?.toLowerCase() as NavigationCardProps['icon']['size'];

	const mappedCards =
		cards?.map((card) => {
			const { heading, icon } = card?.content?.properties ?? {};

			return {
				...card?.content?.properties,
				icon: {
					...icon,
					altText: icon?.alt ?? heading,
					size: iconSize,
				},
				arrowLink: iconSize === 'large' ? true : false,
				centered: true,
			};
		}) ?? [];

	return (
		<Container section width="fluid" spacingBottom={spacingMapper(spacing)}>
			<NavigationCards
				{...content?.properties}
				cards={mappedCards}
				layout={columns}
				backgroundColor={backgroundColor?.toLowerCase() as NavigationCardsProps['backgroundColor']}
				linkType={'card'}
			/>
		</Container>
	);
};
