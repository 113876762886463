import { segment } from 'application/adapters/store/slices/segmentSlice';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { useNavigationContext } from 'application/contexts/NavigationContext';
import { usePageContext } from 'application/contexts/PageContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { cludoRequiredIdsAndClasses } from 'helpers/cludo';
import logger from 'helpers/logger';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { Header } from 'ui/components/4-habitats/Header';
import { Logo } from 'ui/components/2-molecules/Logo';

export interface HeaderFeatureProps {
	themeSettings: Models.Settings.ThemeSettings;
	serviceNavigation: Models.Link[];
	searchPageUrl: string;
}

export const HeaderFeature: React.FC<HeaderFeatureProps> = ({ themeSettings, serviceNavigation, searchPageUrl }) => {
	const router = useRouter();
	const siteContext = useSiteContext();
	const pageContext = usePageContext();
	const navContext = useNavigationContext();
	const dictionary = useDictionaryContext();
	const currentSegment = useSelector(segment);
	const { searchResultsId } = cludoRequiredIdsAndClasses;
	const navigationItems = navContext?.getNavigation({
		host: siteContext?.host,
		currentUrl: pageContext.url,
		currentSegmentPath: currentSegment,
	});
	const headerColorTheme = (themeSettings?.headerColorTheme ?? 'dark') as UI.HeaderColorTheme;

	const handleSearch = (searchValue: string): boolean => {
		const currentPath = location.pathname;
		if (!searchPageUrl || !searchValue) {
			!searchPageUrl &&
				logger.warn(`Search page url is not set on hostname ${location.hostname} and path ${currentPath}`);

			return false;
		}
		const newUrl = new URL(searchPageUrl, location.origin);

		if (!newUrl.pathname) {
			logger.warn('Search page url is not set');
			return false;
		}

		newUrl.searchParams.set('cludoquery', searchValue);
		newUrl.searchParams.set('cludopage', '1');
		newUrl.searchParams.set('cludoinputtype', 'standard');

		router.push(`${newUrl.pathname}#?${newUrl.searchParams.toString()}`).then(() => {
			// Reload page if search page is the same as the current page. This is a workaround to
			// trigger Cludo search while already on the search page.
			if (newUrl.pathname === currentPath) router.reload();

			// Otherwise, scroll to search results
			const searchResults = document.getElementById(searchResultsId);
			searchResults?.scrollIntoView();
		});

		return true;
	};

	return (
		<Header
			logoArea={
				<Logo
					heading={themeSettings?.name}
					// eslint-disable-next-line no-warning-comments
					linkUrl={'/'} // TODO: Change linkUrl to correct path once language settings are implemented
					logoUrl={themeSettings?.logoUrl}
					headerColorTheme={headerColorTheme}
				/>
			}
			navigation={{
				currentPageId: pageContext.id,
				mainNavigation: {
					items: navigationItems,
					ariaLabel: dictionary.getValue('Navigation.MainNavigation', null, 'Main menu'),
					previousButtonText: dictionary.getValue('Navigation.PreviousMenuText', null, 'Back'),
					previousButtonAriaLabel: dictionary.getValue(
						'Navigation.PreviousMenuTextAccessibility',
						null,
						'Back to previous menu',
					),
				},
				serviceNavigation: {
					items: serviceNavigation,
					ariaLabel: dictionary.getValue('Navigation.ServiceNavigation', null, 'Service menu'),
					searchInputPlaceholder: dictionary.getValue('Search.EnterKeyword', null, 'Enter keyword...'),
					searchButtonText: dictionary.getValue('Search.Search', null, 'Search...'),
					handleSearch,
				},
				colorTheme: headerColorTheme,
			}}
			searchBoxProps={
				searchPageUrl && {
					closeButtonText: dictionary.getValue('Search.CloseSearch', null, 'Close search'),
					searchButtonText: dictionary.getValue('Search.Search', null, 'Search...'),
					searchInputPlaceholder: dictionary.getValue('Search.EnterKeyword', null, 'Enter keyword...'),
				}
			}
			toggleMenuButtonText={dictionary.getValue('Navigation.HamburgerMenuText', null, 'Menu')}
			headerColorTheme={headerColorTheme}
			skipToContent={{
				title: dictionary.getValue('Accessibility.SkipToContent.Title', null, 'Skip to content'),
				description: dictionary.getValue(
					'Accessibility.SkipToContent.Description',
					null,
					'Click to skip navigations and go directly to content section',
				),
			}}
		/>
	);
};
