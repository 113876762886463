import cx from 'classnames';
import styles from './FooterCard.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading';

export interface FooterCardProps {
	heading?: string;
	className?: string;
	children?: React.ReactNode;
	theme?: UI.GeneralColorTheme;
}

export const FooterCard: React.FC<FooterCardProps> = ({ className, children, heading, theme = 'dark' }) => (
	<div className={cx(styles.FooterCard, styles[`FooterCard___theme-${theme}`], className)}>
		<div className={styles.FooterCard_textbox}>
			{heading && (
				<Heading headingLevel="h3" style="xs" className={styles.FooterCard_heading}>
					{heading}
				</Heading>
			)}
			<div className={styles.FooterCard_content}>{children}</div>
		</div>
	</div>
);
