import cx from 'classnames';
import styles from './Main.module.scss';

export interface MainProps {
	header?: React.ReactNode;
	children: React.ReactNode;
	breadcrumb?: React.ReactNode;
	renderAsArticle?: boolean;
	contentTopSpacing?: boolean;
}

export const Main: React.FC<MainProps> = ({
	header,
	children,
	breadcrumb,
	renderAsArticle,
	contentTopSpacing = true,
}) => {
	const ArticleWrapper = renderAsArticle ? 'article' : 'div';
	return (
		<>
			{breadcrumb}
			<main id="main" className={styles.Main}>
				{header && Object.entries(header)?.length > 0 && header}
				<ArticleWrapper
					className={cx(styles.Main_article, { [styles.Main_article___topSpacing]: contentTopSpacing })}
				>
					{children}
				</ArticleWrapper>
			</main>
		</>
	);
};
