import cx from 'classnames';
import FocusTrap from 'focus-trap-react';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';
import { useEscapeKey } from 'ui/hooks/useEscapeKey';
import IconCloseCross from 'ui/icons/icon_close_cross.svg';
import styles from './Modal.module.scss';
import { Button } from 'ui/components/1-atoms/Action';
import { Heading } from 'ui/components/1-atoms/Heading';
import { Lead } from 'ui/components/1-atoms/Lead';

export interface ModalProps {
	className?: string;
	isOpen: boolean;
	title?: string;
	text?: string;
	ariaClose: string;
	handleClose: () => void;
	children: React.ReactNode;
	buttonText?: string;
	buttonHandler?: () => void;
}

export const Modal: React.FC<ModalProps> = ({
	className,
	isOpen = false,
	title,
	text,
	handleClose,
	ariaClose,
	children,
	buttonText,
	buttonHandler,
}) => {
	const nodeRef = useRef(null);
	useEscapeKey(handleClose);

	return (
		<Transition nodeRef={nodeRef} timeout={300} in={isOpen} appear={true}>
			{(state) => {
				if (state === 'exited') return null;

				return (
					<FocusTrap>
						<div
							className={cx(styles.Modal, styles[`Modal___${state}`], className)}
							role="dialog"
							aria-modal={true}
							aria-labelledby="dialog-title"
							aria-describedby="dialog-description"
							ref={nodeRef}
						>
							<div className={cx(styles.Modal_overlay)} onClick={handleClose}></div>
							<div className={styles.Modal_content}>
								<button className={styles.Modal_close} onClick={handleClose} aria-label={ariaClose}>
									<IconCloseCross className={styles.Modal_closeIcon} />
								</button>
								{title && (
									<Heading
										id="dialog-title"
										className={styles.Modal_heading}
										headingLevel="h2"
										style="md"
									>
										{title}
									</Heading>
								)}
								{text && (
									<Lead id="dialog-description" className={styles.Modal_text} size="lg">
										{text}
									</Lead>
								)}
								{children}
								{buttonText && (
									<Button className={styles.Modal_button} style="primary" onClick={buttonHandler}>
										{buttonText}
									</Button>
								)}
							</div>
						</div>
					</FocusTrap>
				);
			}}
		</Transition>
	);
};
