import { functionNotNull } from 'helpers/functionNotNull/functionNotNull';
import { FormEventHandler } from 'react';
import { Notification } from 'ui/components/3-organisms/Notification';
export interface FormProps {
	className?: string;
	id?: string;
	ariaLabelledby?: string;
	children: React.ReactNode;
	errorSummary?: number;
	onSubmit?: FormEventHandler<HTMLFormElement>;
	getErrorTxtFromSummary: (summary: number) => string;
}

export const Form: React.FC<FormProps> = ({
	className,
	id,
	ariaLabelledby,
	children,
	errorSummary,
	onSubmit,
	getErrorTxtFromSummary,
}) => {
	let errorMessage: React.ReactNode;
	if (errorSummary > 0 && functionNotNull(getErrorTxtFromSummary)) {
		errorMessage = (
			<Notification hidden>
				<p>{getErrorTxtFromSummary(errorSummary)}.</p>
			</Notification>
		);
	}
	return (
		<form id={id} className={className} onSubmit={onSubmit} aria-labelledby={ariaLabelledby}>
			{errorMessage}
			{children}
		</form>
	);
};
