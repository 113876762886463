import { DynamicModuleFeature } from 'features/Modules';
import { BreadcrumbFeature } from 'features/Modules/Breadcrumb';
import { Main } from 'ui/components/4-habitats/Main';

export const SearchPageFeature: React.FC<Content.SearchPage> = ({ properties }) => {
	const { pageHeader } = properties ?? {};

	return (
		<Main
			header={pageHeader.map((headerModule, index) => (
				<DynamicModuleFeature key={index} contentModule={headerModule} />
			))}
			breadcrumb={<BreadcrumbFeature />}
			renderAsArticle={false}
			contentTopSpacing={false}
		>
			{/* Children is required in Main, but not necessary for this particular page type, so we pass an empty fragment instead */}
			<></>
		</Main>
	);
};
