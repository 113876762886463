import cx from 'classnames';
import React from 'react';
import styles from './Caption.module.scss';

export interface CaptionProps extends UI.Text {
	style?: 'primary' | 'secondary';
	renderParagraph?: boolean;
}

export const Caption: React.FC<CaptionProps> = ({ children, className, style = 'primary', renderParagraph }) => {
	const CaptionWrapper = renderParagraph ? 'p' : 'figcaption';

	return (
		<CaptionWrapper className={cx(styles.Caption, styles[`Caption___${style}`], className)}>
			{children}
		</CaptionWrapper>
	);
};
