import cx from 'classnames';
import styles from './GridCell.module.scss';

type GridCellWidths = '10' | '20' | '25' | '30' | '33' | '50' | '66' | '70' | '75' | '80' | '100' | '';

export interface GridCellProps {
	className?: string;
	children: React.ReactNode;
	center?: boolean;
	noGutter?: boolean;
	mobileWidth?: GridCellWidths;
	tabletWidth?: GridCellWidths;
	desktopWidth?: GridCellWidths;
	asListItem?: boolean;
}

export const GridCell: React.FC<GridCellProps> = ({
	className,
	mobileWidth = '100',
	tabletWidth = '100',
	desktopWidth = '100',
	noGutter,
	children,
	center,
	asListItem,
}) => {
	const Wrapper = asListItem ? 'li' : 'div';
	return (
		<Wrapper
			className={cx(
				styles.GridCell,
				styles[`GridCell--width-100`],
				{ [styles[`GridCell--width-${mobileWidth}@xs`]]: mobileWidth },
				{ [styles[`GridCell--width-${tabletWidth}@md`]]: tabletWidth },
				{ [styles[`GridCell--width-${desktopWidth}@lg`]]: desktopWidth },
				{ [styles[`GridCell--center`]]: center },
				{ [styles[`GridCell___no-gutter`]]: noGutter },
				className,
			)}
		>
			{children}
		</Wrapper>
	);
};
