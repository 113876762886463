import cx from 'classnames';
import styles from './FormfieldToggle.module.scss';

export interface FormfieldToggleProps {
	className?: string;
	id: string;
	label: {
		active: string;
		inactive: string;
	};
	name?: string;
	state?: {
		disabled?: boolean;
		hasError?: boolean;
	};
}

export const FormfieldToggle: React.FC<FormfieldToggleProps> = ({ className, state, id, label, name }) => {
	const { disabled, hasError } = state ?? {};

	return (
		<div
			className={cx(
				styles.FormfieldToggle,
				className,
				{ [styles.hasError]: hasError },
				{ [styles.isDisabled]: disabled },
			)}
		>
			<input className={styles.FormfieldToggle_control} id={id} type="checkbox" disabled={disabled} name={name} />
			<label className={styles.FormfieldToggle_label} htmlFor={id}>
				<span className={styles.FormfieldToggle_labelActive}>{label.active}</span>
				<span className={styles.FormfieldToggle_labelInactive}>{label.inactive}</span>
			</label>
		</div>
	);
};
