import { useEffect, useState } from 'react';

export type MqBreakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl';
export type MqWidthMode = 'min' | 'max';

export const fallbackBreakpointMap: { [key: string]: number } = {
	xs: 320,
	sm: 640,
	md: 768,
	lg: 1248,
	xl: 1440,
	'2xl': 1680,
	'3xl': 1920,
};

export const getMediaQueryString = (
	preferredBreakpoint: MqBreakpoint,
	computedStyleValue: string,
	width: MqWidthMode,
): string => {
	const trimmed = Number(computedStyleValue?.trim());

	const handleWidth = (point: number) => {
		return `${width === 'max' ? point - 1 : point}px`;
	};
	const result = `(${width}-width: ${handleWidth(
		trimmed > 0 ? trimmed : fallbackBreakpointMap[preferredBreakpoint],
	)})`;
	return result;
};

export const useBreakpoint = (breakpoint: MqBreakpoint, width: MqWidthMode = 'min'): boolean => {
	const computedStyleValue =
		typeof window !== 'undefined'
			? window.getComputedStyle(document.documentElement).getPropertyValue(`--breakpoint--${breakpoint}`)
			: '';

	const mediaQuery = getMediaQueryString(breakpoint, computedStyleValue, width);
	const initialState = typeof window !== 'undefined' ? window.matchMedia(mediaQuery)?.matches : true;
	const [isMobile, setIsMobile] = useState(initialState);

	const handleResize = (list: MediaQueryListEvent) => {
		setIsMobile(list.matches);
	};

	useEffect(() => {
		const mediaQueryList = window.matchMedia(mediaQuery);
		mediaQueryList.addEventListener('change', handleResize);
		return () => {
			mediaQueryList.removeEventListener('change', handleResize);
		};
	});

	return isMobile;
};
