export const cludoRequiredIdsAndClasses = {
	formId: 'cludo-search-form',
	searchInputId: 'cludo-search-input',
	searchInputContainerId: 'cludo-search-input-container',
	searchButtonId: 'search-button',
	searchFiltersClass: 'search-filters',
	searchResultsId: 'cludo-search-results',
	searchResultsClass: 'search-results',
	searchResultCountClass: 'search-result-count',
	searchDidYouMeanClass: 'search-did-you-mean',
};
