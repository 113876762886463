import { JobCard, JobCardProps } from 'ui/components/2-molecules/Card/JobCard';
import styles from './JobList.module.scss';
import { LinkButton } from 'ui/components/1-atoms/Action';
import { Grid, GridCell } from 'ui/components/4-habitats/Grid';
import { SearchResultWrapper } from '../SearchResultWrapper';
export interface JobListProps {
	link?: Content.Link;
	jobs?: JobCardProps[];
	isLoading: boolean;
	heading: string;
}

export const JobList: React.FC<JobListProps> = ({ jobs, link, heading, isLoading }) => {
	const linkButton: JSX.Element = link ? (
		<LinkButton title={link.name} style="secondary" target={link.target} url={link.url}>
			{link.name}
		</LinkButton>
	) : null;

	return (
		<div className={styles.JobList}>
			<SearchResultWrapper
				heading={heading}
				isLoading={isLoading}
				className={styles.JobList_wrapper}
				linkButton={linkButton}
			>
				{jobs && jobs.length > 0 && (
					<Grid className={styles.JobList_grid} wrap asList nogutter>
						{jobs
							.sort((a, b) => b.job.dateTime.getDate() - a.job.dateTime.getDate())
							.map((item, index) => (
								<GridCell
									key={index}
									className={styles.JobList_cell}
									desktopWidth={'50'}
									tabletWidth={'50'}
									mobileWidth={'100'}
									center={false}
									asListItem
								>
									<JobCard className={styles.JobList_card} {...item} />
								</GridCell>
							))}
					</Grid>
				)}
			</SearchResultWrapper>
		</div>
	);
};
