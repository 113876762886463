import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
import { axiosInstance as axios } from 'helpers/axios/axiosInstance';
import logger from 'helpers/logger';

export interface TeaserBaseParams {
	rootKey: string;
	categories: { name: string }[];
	host: string;
}

export interface NewsTeaserParams extends TeaserBaseParams {
	documentTypes: string[];
	limit: number;
	teaserTags: {
		id: number;
		name: string;
	}[];
	from?: string;
	direction?: 'ascending' | 'descending'; // API default is 'descending'
}

export const getTeasersFromApi = async (params: NewsTeaserParams): Promise<Models.TeaserArticle[]> => {
	const { rootKey, documentTypes, categories, teaserTags, limit, host, from, direction } = params;
	const url = `${publicRuntimeConfig.NEXT_PUBLIC_SEARCH_API_URL}api/TeaserBanner/Search`;
	if (params.rootKey === '') {
		logger.error(`teaserRepository.getTeasersFromApi(): Skipped ${url} since no rootKey was applied.`);
		return Promise.reject('No rootKey applied.');
	}

	const bodyContent = JSON.stringify({
		key: rootKey,
		DocumentTypes: documentTypes || ['articlePage'],
		categories,
		teaserTags,
		takeAmount: limit,
		from,
		direction,
	});
	const headersList = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		Hostname: host,
	};
	const reqOptions = {
		url: url,
		method: 'POST',
		headers: headersList,
		data: bodyContent,
	};
	const profiler = logger.startTimer();
	return axios
		.post(reqOptions.url, reqOptions.data, { headers: reqOptions.headers })
		.then((res) => {
			return res.data.searchResults.map((elm) => {
				return elm as Models.TeaserArticle;
			});
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			logger.error(`teaserRepository.getTeasersFromApi() failed: ${message}, url: ${url}`, error);
			throw message;
		})
		.finally(() => {
			profiler.done({ message: 'WEBSITE: getTeasersFromApi()', level: 'info' });
		});
};
