import cx from 'classnames';
import styles from './Hamburger.module.scss';

export interface HamburgerProps {
	className?: string;
	isActive: boolean;
}

export const Hamburger: React.FC<HamburgerProps> = ({ className, isActive }) => (
	<div className={cx(styles.Hamburger, { [styles.Hamburger___isActive]: isActive }, className)}>
		<span className={styles.Hamburger_line} />
		<span className={styles.Hamburger_line} />
		<span className={styles.Hamburger_line} />
	</div>
);
