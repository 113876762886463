import cx from 'classnames';
import { Container } from 'ui/components/4-habitats/Container';
import styles from './Hero.module.scss';
import { useBreakpoint } from 'ui/hooks/useBreakpoint';
import { useEffect, useState } from 'react';
import { LinkButton } from 'ui/components/1-atoms/Action';
import { Heading } from 'ui/components/1-atoms/Heading';
import { Lead } from 'ui/components/1-atoms/Lead';

export type VideoType = 'mp4' | 'webm' | 'ogg';
export interface HeroVideoProps {
	src: string;
	type: VideoType;
}
export interface HeroProps extends UI.HeroBase {
	link?: UI.LinkSimple;
	imageUrl: string;
	videos?: HeroVideoProps[];
	heroBox?: React.ReactNode;
}

export const Hero: React.FC<HeroProps> = ({
	heading,
	lead,
	className,
	link,
	heroBox,
	videos = [],
	imageUrl = '',
	theme = 'dark',
}) => {
	const isSM = useBreakpoint('sm', 'max');
	const isMD = useBreakpoint('md', 'max');
	const isLG = useBreakpoint('lg', 'max');
	const isXL = useBreakpoint('xl', 'max');

	const [imageWidth, setImageWidth] = useState<number>(0);

	useEffect(() => {
		// No need to calc anything if we already have set the largest possible imageWidth
		if (imageUrl && imageWidth < 1920) {
			const calcImageWidth = () => {
				if (isSM) return 640;
				if (isMD) return 768;
				if (isLG) return 1248;
				if (isXL) return 1440;
				return 1920;
			};

			const imgWidth = calcImageWidth();

			if (imgWidth > imageWidth) {
				// No need to update imageWidth unless it is bigger than earlier loaded image.
				setImageWidth(imgWidth);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSM, isMD, isLG, isXL]);

	return (
		<Container width={'fluid'}>
			<header
				className={cx(styles.Hero, className, styles[`Hero___theme-${theme}`])}
				style={
					imageUrl && imageWidth > 0
						? { backgroundImage: `url(${imageUrl}?width=${imageWidth}&format=webp)` }
						: undefined
				}
			>
				{videos && videos.length > 0 && (
					<video className={styles.Hero_video} autoPlay muted loop aria-hidden="true">
						{videos.map((video, i) => (
							<source key={i} src={video.src} type={`video/${video.type}`} />
						))}
					</video>
				)}
				<div className={styles.Hero_gradient}></div>
				<Container width={'std'}>
					<div className={styles.Hero_textbox}>
						<Heading className={styles.Hero_heading} headingLevel="h1" style="2xl">
							{heading}
						</Heading>
						{lead && (
							<Lead className={styles.Hero_lead} size={'lg'}>
								{lead}
							</Lead>
						)}
						{link && (
							<LinkButton
								className={styles.Hero_linkbtn}
								style={theme === 'light' ? 'primary' : 'primary-negative'}
								url={link.url}
								target={link.target}
								title={link.title}
							>
								{link?.name ?? ''}
							</LinkButton>
						)}
					</div>
				</Container>
			</header>
			{heroBox && <Container className={styles.Hero_focus}>{heroBox}</Container>}
		</Container>
	);
};
