import React from 'react';

export interface TimeProbs {
	date: Date;
	children: React.ReactNode;
}

export const Time: React.FC<TimeProbs> = ({ children, date }) => {
	return date ? <time dateTime={date.toISOString()}>{children}</time> : <>{children}</>;
};
