import Head from 'next/head';

export const SearchMetadata: React.FC<{ metadata: Search.MetadataItem[] }> = ({ metadata }) => {
	return (
		<Head>
			{metadata.map((item, index) => (
				<meta key={index} name={item.name} content={item.content} />
			))}
		</Head>
	);
};
