import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { usePageContext } from 'application/contexts/PageContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { cludoRequiredIdsAndClasses } from 'helpers/cludo';
import { decodeQueryParamString } from 'helpers/string/string';
import { useEffect, useState } from 'react';
import { CludoSearch } from 'ui/components/3-organisms/CludoSearch';

export const HeroSearchFeature: React.FC<Content.HeroSearch> = ({ content }) => {
	const SiteContext = useSiteContext();
	const PageContext = usePageContext();
	const { themeSettings } = PageContext;
	const dictionary = useDictionaryContext();
	const { heading, image, lead, link, customerId, engineId } = content?.properties ?? {};
	const [isLoading, setIsLoading] = useState(false);
	const [currentQuery, setCurrentQuery] = useState<UI.CludoSearchResponse>();
	const [currentCategory, setCurrentCategory] = useState('');
	const { formId } = cludoRequiredIdsAndClasses;

	const hero = {
		heading,
		lead,
		image: { url: image?.url, altText: '' },
		link,
		theme: themeSettings?.heroAndBreadcrumbColorTheme,
	};

	const cludoDictionary = {
		categoryFilter: {
			toggleButtonText: dictionary.getValue('Search.Filter.SubjectToggle', null, 'Select topic'),
			dropdownAriaLabel: dictionary.getValue('Search.Filter.SubjectToggle', null, 'Select topic'),
		},
		searchFilter: {
			heading: dictionary.getValue('Search.Filter.Heading', null, 'Searching and filtering'),
			clearSearchBtn: dictionary.getValue('Search.Filter.Clear', null, 'Clear search'),
			submitBtn: dictionary.getValue('Search.Filter.Submit', null, 'Search'),
		},
		noScriptTxt: dictionary.getValue(
			'Accessibility.NoJavaScript.Component',
			null,
			'Din browser understøtter ikke JavaScript hvilket kræves for dette komponent.',
		),
	};

	useEffect(() => {
		const cludoSearchSettings: UI.CludoSearchSettings = {
			customerId,
			engineId,
			searchUrl: location.pathname,
			language: SiteContext.culture ? new Intl.Locale(SiteContext.culture)?.language : 'en',
			searchInputs: [formId],
			customerTemplate: 'MST 2023',
			translateSearchTemplates: true,
			focusOnResultsAfterSearch: true,
			jumpToTopOnFacetClick: false,
			customCallbackBeforeSearch: () => setIsLoading(true),
			customCallbackAfterSearch: (response) => {
				setIsLoading(false);
				setCurrentQuery(response);
				const params = new URLSearchParams(location.href);
				setCurrentCategory(params.get('cludoCategory'));
			},
		};

		// Load the Cludo script.
		const script = document.createElement('script');
		script.src = 'https://customer.cludo.com/scripts/bundles/search-script.min.js';
		script.async = true;
		document.body.appendChild(script);

		script.addEventListener('load', () => {
			const CludoSearch = new (window as UI.CludoWindow).Cludo(cludoSearchSettings);
			CludoSearch.init();
			// Trim the search string for search-result-count in SearchResultList
			CludoSearch.params.query = decodeQueryParamString(CludoSearch.params.query);
		});

		return () => {
			// Clean up the Cludo script when the component unmounts
			document.body.removeChild(script);
		};
	}, [formId, customerId, engineId, SiteContext.culture]);

	return (
		<CludoSearch
			hero={hero}
			searchQuery={{ isLoading, queryId: currentQuery?.QueryId, currentCategory }}
			dictionary={cludoDictionary}
		/>
	);
};
