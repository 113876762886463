import styles from './SearchResultItem.module.scss';
import { Time } from 'ui/components/1-atoms/Time';
import { removeHttp } from 'helpers/string/string';
import { Caption } from 'ui/components/1-atoms/Caption';
import { Heading } from 'ui/components/1-atoms/Heading';
import { Paragraph } from 'ui/components/1-atoms/Paragraph';
import { Link } from 'ui/components/1-atoms/Action/Link';

export interface SearchResultItemProps extends UI.PageItem {
	dateTime: Date;
}

export const SearchResultItem: React.FC<SearchResultItemProps> = ({ heading, text, dateString, dateTime, link }) => {
	return (
		<Link className={styles.SearchResultItem} url={link.url}>
			{dateString && (
				<Caption renderParagraph className={styles.SearchResultItem_date}>
					<Time date={dateTime}>{dateString}</Time>
				</Caption>
			)}
			<div className={styles.SearchResultItem_wrapper}>
				<Heading className={styles.SearchResultItem_heading} headingLevel="h3" style="2xs">
					{heading}
				</Heading>
				{text && (
					<Paragraph className={styles.SearchResultItem_text}>
						<span dangerouslySetInnerHTML={{ __html: text }}></span>
					</Paragraph>
				)}
				{link.name && <Paragraph className={styles.SearchResultItem_link}>{removeHttp(link.name)}</Paragraph>}
			</div>
		</Link>
	);
};
